import React, {useEffect, useState} from "react";
import {Case} from "../../API";
import {useUserContext} from "../../context/UserContext";
import CaseCard from "./components/case-card/CaseCard";
import {Notification} from "../../helpers/notification/Notification";
import styles from "styles/styles.module.scss";
import {anyMatchForObjectKey, matchBetween, matchByToggle, SortDirection} from "../../helpers/CommonUtils";
import classNames from "classnames";
import {
    Backdrop,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputBase,
    MenuItem,
    Paper,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {CaseContextProvider} from "../../context/CaseContext";
import {useCaseService} from "../../hooks/services/CaseService";
import {useTranslation} from "react-i18next";


export const CasePage = () => {
    const userContext = useUserContext();
    const {t} = useTranslation();
    const {listCases} = useCaseService();
    const [renderCaseCreation, setRenderCaseCreation] = useState(false);
    const [cases, setCases] = useState<Array<Case>>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [sort, setSort] = useState<SortDirection>("NONE");
    const [ownedCase, setOwnedCase] = useState(false);
    const [searchItem, setSearchItem] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        listCases()
            .then(c => setCases(c))
            .catch(() => Notification.error("Could not retrieve cases information."))
            .finally(() => setLoading(false));
    }, [listCases])

    const addNewCase = (newCase: Case) => {
        setCases([newCase, ...cases]);
        setRenderCaseCreation(false);
    }

    const renderCases = () => {
        if(cases && cases.length > 0) {
            return cases?.filter(c => filterItem(c))
                .sort((a, b) => sort === "ASC" && (a.createdAt > b.createdAt) ? -1 : 1)
                .sort((a, b) => sort === "DESC" && (a.createdAt < b.createdAt) ? 1 : -1)
                .map(c => <CaseContextProvider caseId={c.id} key={c.id}>
                        <CaseCard/>
                    </CaseContextProvider>
                );
        }
        return <Card className={styles.card}>
            <CardContent sx={{display: "flex", flexDirection: "column"}}>
                <Typography sx={{margin: "1rem"}}>
                    {t("content.case.creation.firstCase")}
                </Typography>
                <Button sx={{margin: "1rem"}} variant={"contained"} onClick={() => setRenderCaseCreation(!renderCaseCreation)}>
                    {t("content.case.creation.button")}
                </Button>
            </CardContent>
        </Card>

    }

    const filterItem = (object: Case) => {
        const search = anyMatchForObjectKey(object, ["name", "description"], searchItem)
        const byDate = matchBetween(object, "createdAt", startDate, endDate)
        const owned = matchByToggle(object, "owner", ownedCase, userContext.user.getUsername());
        return search && byDate && owned;
    }

    const clearFilter = () => {
        setSearchItem("");
        setSort("NONE");
    }

    const renderFilterBar = () => {
        return <Paper className={classNames(styles.filterContainer, styles.sticky, styles.primary)}>
            <div className={styles.filterBar}>
                <Tooltip title="Neue Initiative erstellen">
                    <IconButton size="medium"
                                color={"primary"}
                                onClick={() => setRenderCaseCreation(!renderCaseCreation)}
                    >
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
                <InputBase
                    placeholder="Suche Initiative"
                    inputProps={{"aria-label": "Suche Initiative"}}
                    onChange={(e) => setSearchItem(e.target.value)}
                    value={searchItem}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                />
                <Divider orientation="vertical"/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={ownedCase}
                            onChange={() => setOwnedCase(!ownedCase)}/>
                    }
                    label="Meine Initiativen"
                />
                <TextField className={styles.formControl} label={"Sortierung"} size="small" select value={sort} onChange={(e) => setSort(e.target.value as SortDirection)}>
                    <MenuItem value={"NONE"}>Keine</MenuItem>
                    <MenuItem value={"ASC"}>Neueste zuerst</MenuItem>
                    <MenuItem value={"DESC"}>Neueste zuletzt</MenuItem>
                </TextField>
                <DatePicker label="Datum ab"
                            slotProps={{textField: {size: 'small'}}}
                            maxDate={dayjs()}
                            onChange={(e) => setStartDate(e?.toDate())}
                />
                <DatePicker label="Datum bis"
                            slotProps={{textField: {size: 'small'}}}
                            maxDate={dayjs()}
                            onChange={(e) => setEndDate(e?.toDate())}
                />
                <Tooltip title="Filter zurücksetzen">
                    <IconButton size="medium" onClick={() => clearFilter()} color={"primary"} sx={{m: 1}}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    }

    const renderCasePage = () => {
        return <>
            {renderFilterBar()}
            {renderCaseCreation && <CaseContextProvider>
                <CaseCard onCreate={(c: Case) => addNewCase(c)}/>
            </CaseContextProvider>
            }
            {renderCases()}
            {loading && <Backdrop open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            }
        </>
    }

    return renderCasePage()
}
