import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {Audit, AuditPhase, Case} from "../API";
import {useUserContext} from "./UserContext";
import {Notification} from "../helpers/notification/Notification";
import {useCaseService} from "../hooks/services/CaseService";
import {useAuditService} from "../hooks/services/AuditService";
import {isNotBlank} from "../helpers/CommonUtils";


type CaseContextType = {
    currentCase?: Case,
    audits: Array<Audit>,
    updateAudits: Function,
    updateCase: Function,
    setCase: Function,
    setAudits: Function,
    updating: boolean,
    setUpdating: Function,
    currentPhase: AuditPhase,
    setCurrentPhase: Function,
}

const CaseContext = createContext<CaseContextType | null>(null);

type CaseContextProviderProps = {
    caseId?: string,
    children: ReactNode
}

const CaseContextProvider = ({caseId, children}: CaseContextProviderProps) => {
    const {getCaseById} = useCaseService();
    const {getAuditsByCaseID} = useAuditService();
    const userContext = useUserContext();
    const [currentCase, setCase] = useState<Case>();
    const [currentPhase, setCurrentPhase] = useState<AuditPhase>(AuditPhase.basic);
    const [audits, setAudits] = useState<Array<Audit>>([]);
    const [updating, setUpdating] = useState<boolean>(false);

    useEffect(() => {
        const retrieveCase = async () => {
            if (caseId) {
                const caseData:Case = await getCaseById(caseId);
                setCase(caseData);
                setCurrentPhase(caseData?.phase);
                setAudits(caseData.audits?.items as Array<Audit>);
            }
        }
        setUpdating(true);
        retrieveCase()
            .catch((e) => Notification.error("Could not get case information. Try later.", e))
            .finally(() => setUpdating(false));
    }, [caseId, getCaseById, userContext.user])

    const updateAudits = useCallback(async () => {
        if (caseId && isNotBlank(caseId)) {
            setUpdating(true);
            getAuditsByCaseID(caseId)
                .then(a => setAudits(a))
                .catch((e) => Notification.error("Could not update audits. Try later.", e))
                .finally(() => setUpdating(false));
        }
    }, [caseId, getAuditsByCaseID])

    const updateCase = useCallback(() => {
        if (caseId) {
            setUpdating(true);
            getCaseById(caseId)
                .then(c => setCase(c))
                .finally(() => setUpdating(false));
        }
    }, [caseId, getCaseById])

    return (
        <CaseContext.Provider value={{currentCase: currentCase, audits: audits, updateAudits, updateCase, setCase, setAudits, updating, setUpdating, currentPhase, setCurrentPhase}}>
            {children}
        </CaseContext.Provider>
    );
};


const useCaseData = (): CaseContextType => {
    const context = useContext(CaseContext);
    if (context === null) {
        throw new Error("CaseContext was used outside of its Provider");
    }
    return context;
}

export {CaseContext, CaseContextProvider, useCaseData};