import _ from "lodash";
import dayjs from "dayjs";

export const isNotBlank = (data: string | undefined | boolean): boolean => data !== undefined && typeof data == "string" && data.trim().length > 0;
export const isBlank = (data: string | undefined): boolean => !isNotBlank(data);

export type SortDirection = "ASC" | "DESC" | "NONE"

export const MATCH_ALL = "all";

export enum SelectionEntry {
    ALL = "all",
    TRUE = "true",
    FALSE = "false",
    YES = "yes",
    NO = "no",
}

export const omitObjectPaths = <T extends {}>(objectArray: Array<any>, finalPaths: Array<string>) => {
    if (objectArray) {
        return objectArray.map(s => {
            const paths = Object.keys(s).filter(k => !finalPaths.includes(k))
            return _.omit(s, paths) as T;
        });
    }
    return [];
}

export const omitGQLTypenames = <T extends {}>(objectArray: Array<any>) => {
    if (objectArray) {
        return objectArray.map(s => omitGQLTypename<T>(s));
    }
    return [];
}

export const omitGQLTypename = <T extends {}>(object: any): T => {
    return _.omit(object, ["__typename"]) as T;
}

const isSelectionEntry = (x: any): x is SelectionEntry => Object.values(SelectionEntry).includes(x);

export type SelectionResult = SelectionEntry.ALL | boolean;

export const toSelectionResult = (value: SelectionEntry): SelectionResult => {
    switch (value) {
        case SelectionEntry.TRUE || SelectionEntry.YES:
            return true;
        case SelectionEntry.FALSE || SelectionEntry.NO:
            return false;
        default:
            return SelectionEntry.ALL;
    }
}

export const matchByToggle = (object: any, key: string, toggleValue: boolean, value?: string) => {
    if (isNotBlank(value)) {
        return toggleValue ? object[key] === value : 1;
    }
    return toggleValue ? object[key] : 1;
}

export const matchBySelectionList = (object: any, key: string, selections: string[]) => {
    return selections.length === 0 ? object : selections.includes(object[key])
}

export const matchBetween = (object: any, key: string, from: Date | undefined, to: Date | undefined) => {
    if (from && to) {
        return object[key] > from.toISOString() && object[key] < to.toISOString();
    } else if (from) {
        return object[key] > from.toISOString();
    } else if (to) {
        return object[key] < to.toISOString();
    }
    return 1;
}

export const anyMatchForObjectKey = (object: any, keys: Array<string>, value: string | boolean | SelectionEntry) => {
    if ((typeof value === "string" && isBlank(value)) || value === MATCH_ALL || value === SelectionEntry.ALL) {
        return true;
    }

    return keys.some(key => {
        const entry = object[key];
        if (typeof entry === "string" && typeof value === "string" && isNotBlank(value)) {
            return entry.toLowerCase().includes(value.toLowerCase());
        } else if (typeof entry === "boolean" && typeof value === "boolean") {
            return entry === value;
        } else if (isSelectionEntry(value)) {
            return entry === toSelectionResult(value);
        }
        return false;
    });
}

export const toMilliseconds = (hrs: number, min: number, sec: number) => (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const getDayMonthDate = (date: string) => {
    if (date)
        return dayjs(date).format("DD. MMMM").toString();
    return dayjs().format("DD. MMMM").toString();
}
