import React from "react";
import {AnswerSelection} from "../../../models";
import AnswerAuditReference from "./AnswerAuditReference";
import {Button, IconButton, List, ListItem, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./answer.module.scss";
import {useAuditData} from "../../../context/AuditEditorContext";

type AnswerSelectionsEditorProps = {
    answerSelections: Array<AnswerSelection> | [];
    className?: any;
    onChange?: Function;
}

function AnswerSelectionsEditor(props: AnswerSelectionsEditorProps) {
    const {answerSelections} = props;
    const {onChange} = props;
    const audit = useAuditData();

    const addSelection = () => {
        let temp = [...answerSelections];
        temp.push({value: "", selected: false, auditReferences: []});
        onChange && onChange(temp);
    }

    const removeSelection = (index: number) => {
        const temp = [...answerSelections];
        temp.splice(index, 1);
        onChange && onChange(temp);
    }

    const changeSelectionValue = (index: number, selectionValue: string, references: Array<string>) => {
        const temp = [...answerSelections];
        temp[index] = {selected: false, value: selectionValue, auditReferences: references}
        onChange && onChange(temp);
    }

    return <div className={styles.selectionsContainer}>
        <Button onClick={() => addSelection()} variant={"contained"}>Eintrag hinzufügen</Button>
        <List>
            {answerSelections?.filter((x): x is AnswerSelection => x !== null)
                .map((a: AnswerSelection, index: number) =>
                    <ListItem key={audit.id + index}
                              alignItems={"flex-start"}
                              disableGutters
                              secondaryAction={
                                  <IconButton edge="end" aria-label="delete" onClick={() => removeSelection(index)}>
                                      <DeleteIcon/>
                                  </IconButton>
                              }
                    >
                        <TextField className={styles.selectionText}
                                   label="Auswahl"
                                   defaultValue={a.value}
                                   onChange={(e) => changeSelectionValue(index, e.target.value, [])}
                        />
                        <AnswerAuditReference className={styles.selectionReference}
                                              answerSelection={a}
                                              onChange={(v: Array<string>) => changeSelectionValue(index, a.value || "", v)}
                        />
                    </ListItem>
                )
            }
        </List>
    </div>
}

export default AnswerSelectionsEditor;

