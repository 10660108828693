import {RefObject, useEffect, useMemo, useState} from "react";


export default function useOnScreen(ref: RefObject<HTMLElement>, onlyOnce: boolean = false) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            if (onlyOnce) {
                if(entry.isIntersecting) {
                    setIntersecting(entry.isIntersecting) 
                }
            } else {
                setIntersecting(entry.isIntersecting);
            }
        }
    ), [onlyOnce]);

    useEffect(() => {
        if (ref.current)
            observer.observe(ref.current);
        return () => observer.disconnect()
    }, [observer, ref])

    return isIntersecting
}