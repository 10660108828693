import React from "react";

import styles from "./sidenav.module.scss";
import classNames from "classnames";
import {Drawer, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import {NavigationRoutes} from "../../NavigationRoutes";
import {isNotBlank} from "../../helpers/CommonUtils";
import {useTranslation} from "react-i18next";
import {useUserData} from "../../context/UserContext";

export const AppSideNav = () => {
    const {t} = useTranslation();
    const userData = useUserData();
    const [open, setOpen] = React.useState(false);

    const getLinkStyles = (isActive: boolean) => {
        if (isActive)
            return classNames(styles.navLink, styles.active)
        return styles.navLink;
    }

    return <Drawer variant="permanent" open={open}>
        <div onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <div className={styles.sidenav}>
                <div className={styles.sidenavContent}>
                    {NavigationRoutes
                        .filter(a => isNotBlank(a.i8nLabel))
                        .filter(a => {
                            if (a.groups && userData) {
                                return a.groups.some(r => userData["custom:azure_groups"].includes(r));
                            }
                            return true;
                        })
                        .map((r) =>
                            <NavLink key={r.path} className={({isActive}) => getLinkStyles(isActive)}
                                     to={r.path}
                                     aria-label="link">
                                <div className={styles.navLinkIcon}>{r.menuIcon}</div>
                                <Typography className={styles.navLinkText}>{t(r.i8nLabel)}</Typography>
                            </NavLink>
                        )
                    }
                </div>
            </div>
        </div>
    </Drawer>
};
