import {useUserToken} from "../../context/UserContext";
import {useCallback} from "react";
import {
    Article,
    CreateArticleInput,
    CreateArticleMutation,
    DeleteArticleInput,
    DeleteArticleMutation,
    GetArticleQuery,
    ListArticlesQuery,
    ListAuditsQueryVariables,
    SearchableArticleSortableFields,
    SearchableSortDirection,
    SearchArticlesQuery,
    SearchArticlesQueryVariables,
    UpdateArticleInput,
    UpdateArticleMutation
} from "../../API";
import {API, graphqlOperation, GraphQLQuery} from "@aws-amplify/api";
import {getArticle as getArticleOperation, listArticles as listArticlesOperation, searchArticles as searchArticlesOperation} from "../../graphql/queries";
import {createArticle as createArticleOperation, deleteArticle as deleteArticleOperation, updateArticle as updateArticleOperation} from "../../graphql/mutations";

export const useArticleService = () => {
    const {getJwtToken} = useUserToken();

    const searchArticles = useCallback(async (input: SearchArticlesQueryVariables): Promise<Array<Article>> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<SearchArticlesQuery>>(graphqlOperation(searchArticlesOperation, input, token))
        return data?.searchArticles?.items as Array<Article>;
    }, [getJwtToken]);

    const createArticle = useCallback(async (input: CreateArticleInput): Promise<Article> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<CreateArticleMutation>>(graphqlOperation(createArticleOperation, {input: input}, token))
        return data?.createArticle as Article;
    }, [getJwtToken]);

    const listArticles = useCallback(async (input: ListAuditsQueryVariables = {}): Promise<Array<Article>> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<ListArticlesQuery>>(graphqlOperation(listArticlesOperation, input, token))
        return data?.listArticles?.items as Array<Article>;
    }, [getJwtToken]);

    const getArticle = useCallback(async (id: string): Promise<Article> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<GetArticleQuery>>(graphqlOperation(getArticleOperation, {id: id}, token));
        return data?.getArticle as Article;
    }, [getJwtToken]);

    const updateArticle = useCallback(async (input: UpdateArticleInput): Promise<Article> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<UpdateArticleMutation>>(graphqlOperation(updateArticleOperation, {input: input}, token));
        return data?.updateArticle as Article;
    }, [getJwtToken]);

    const deleteArticle = useCallback(async (input: DeleteArticleInput): Promise<Article> => {
        const token = await getJwtToken();
        const response = await API.graphql<GraphQLQuery<DeleteArticleMutation>>(graphqlOperation(deleteArticleOperation, {input: input}, token))
        return response.data?.deleteArticle as Article
    }, [getJwtToken]);

    return {createArticle, updateArticle, listArticles, searchArticles, getArticle, deleteArticle};
}