import classNames from "classnames";
import styles from "../../home-page.module.scss";
import {Grow, Paper} from "@mui/material";
import React, {useRef} from "react";
import {TeamContact} from "./TeamContact";
import useOnScreen from "../../../../hooks/useOnScreen";
import {Team} from "./Team";
import {useTranslation} from "react-i18next";


export const TeamsSection = () => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, true);
    const {t} = useTranslation();

    const renderCoreMembers = () => {
        return <Team className={classNames(styles.teamGroup, styles.odd)}
                     title={"content.homepage.teamSection.core.title"}>
            <TeamContact mail={"FAlsmann@vhv.de"} name={"Frank Alsmann"} role={`[GL-DDA-02]: ${t('content.common.roles.management')}`}/>
            <TeamContact mail={"MRiffel@vhv.de"} name={"Max Riffel"} role={`[DDA-02]: ${t('content.common.roles.development')}`} image={"/assets/avatar/mriffel.jpg"}/>
            <TeamContact mail={"LHou@vhv.de"} name={"Lei Hou"} role={`[DDA-02]: ${t('content.common.roles.development')}`}/>
            <TeamContact mail={"WaFocht@vhv.de"} name={"Waldemar Focht"} role={`[DDA-02]: ${t('content.common.roles.security')}`}/>
            <TeamContact mail={"SWinter@vhv.de"} name={"Sebastian Winter"} role={`[DDA-02]: ${t('content.common.roles.operations')}`}/>
            <TeamContact mail={"lpape@vhv.de"} name={"Lucas Pape"} role={`[DDA-02]: ${t('content.common.roles.operations')}`}/>
        </Team>
    }

    const renderSupportMembers = () => {
        return <Team className={classNames(styles.teamGroup, styles.even)}
                     title={"content.homepage.teamSection.support.title"}
                     orientation={"right"}>
            <TeamContact mail={"TMierse@vhv.de"} name={"Thorsten Mierse"} role={`[DDI-03]: ${t('content.common.roles.operations')}`}/>
            <TeamContact mail={"JWiedemann@vhv.de"} name={"Jörg Wiedemann"} role={`[DDI-02]: ${t('content.common.roles.operations')}`}/>
            <TeamContact mail={"RBertram@vhv.de"} name={"Rouven Bertram"} role={`[DDI-02]: ${t('content.common.roles.operations')}`}/>
            <TeamContact mail={"ESarioglu@vhv.de"} name={"Elif Sarioglu"} role={`[DDS-01]: ${t('content.common.roles.architecture')}`}/>
        </Team>
    }

    const renderConsultingMembers = () => {
        return <Team className={classNames(styles.teamGroup, styles.odd)}
                     title={"content.homepage.teamSection.extern.title"}>
            <TeamContact mail={"JHockenberger@vhv.de"} name={"Jana Hockenberger"} role={`[Extern]: ${t('content.common.roles.development')}`}/>
            <TeamContact mail={"PZink@vhv.de"} name={"Patrick Zink"} role={`[Extern]: ${t('content.common.roles.development')}`}/>
            <TeamContact mail={"Michael.Walther@nbasics.de"} name={"Michael Walther"} role={`[Extern]: ${t('content.common.roles.consulting')}`}/>
            <TeamContact mail={"NWebb@vhv.de"} name={"Nickolas Webb"} role={`[Extern]: ${t('content.common.roles.consulting')}`}/>
        </Team>
    }

    return <Grow in={isVisible} timeout={1000}>
        <Paper ref={ref} className={classNames(styles.sectionContainer, styles.teamSection)} variant="outlined">
            {renderCoreMembers()}
            {renderSupportMembers()}
            {renderConsultingMembers()}
        </Paper>
    </Grow>
}