import {Accordion, AccordionDetails, Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Step, StepButton, Stepper} from "@mui/material";
import {AnswerSelection, AuditPhase, CaseStatus, Question} from "../../../API";
import QuestionCarousel from "../audit-question/carousel/QuestionCarousel";
import React, {useCallback, useEffect, useState} from "react";
import AuditQuestion from "../audit-question/AuditQuestion";
import {QuestionCarouselItem} from "../audit-question/carousel/QuestionCarouselItem";
import {Notification} from "../../../helpers/notification/Notification";
import {useAuditAllocationService} from "../../../hooks/services/AuditAllocationService";
import {useQuestionService} from "../../../hooks/services/QuestionService";
import {useCaseData} from "../../../context/CaseContext";
import {CarouselFilter} from "../audit-question/carousel/CarouselFilter";
import styles from "./audit-phase-stepper.module.scss";
import {TranslatedTypography} from "../../common/TranslatedTypography";
import {useTranslation} from "react-i18next";

type AuditPhaseStepperProps = {
    onChange?: Function
}

export const AuditPhaseStepper = ({onChange}: AuditPhaseStepperProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const {t} = useTranslation();
    const {currentCase, audits, updateAudits, updating, currentPhase, setCurrentPhase} = useCaseData();
    const {allocateAuditToCase} = useAuditAllocationService();
    const {listQuestionsByAuditId} = useQuestionService();
    const [questions, setQuestions] = useState<Array<Question>>([]);
    const [filteredQuestions, setFilteredQuestions] = useState<Array<Question>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [openConfirmReviewModal, setOpenConfirmReviewModal] = useState<boolean>(false);

    useEffect(() => {
        if (audits) {
            const retrieveAudits = async () => {
                let overallQuestions: Array<Question> = [];
                for (const audit of audits.filter(a => a.phase === currentPhase && a.isActive)) {
                    const questionData = await listQuestionsByAuditId(audit.id);
                    overallQuestions.push(...questionData);
                }
                setQuestions(overallQuestions);
            }
            setLoading(true);
            retrieveAudits()
                .catch((e) => Notification.error("Could not get question information.", e))
                .finally(() => setLoading(false))
        } else {
            setQuestions([]);
        }
    }, [currentPhase, audits, listQuestionsByAuditId])

    const changeAuditsView = (phase: AuditPhase) => {
        if (phase === currentPhase) {
            setExpanded(!expanded);
        } else {
            setExpanded(true);
        }
        setCurrentPhase(phase);
    }

    const getQuestionCarouselTitle = (): string => {
        switch (currentPhase) {
            case AuditPhase.basic:
                return "Basis Fragen";
            case AuditPhase.thematic:
                return "Thematische Fragen";
            case AuditPhase.specific:
                return "Spezifische Fragen";
            default:
                return "Basis Fragen";
        }
    }

    const updateFilteredQuestions = useCallback((out: Array<Question>) => setFilteredQuestions(out), [])

    const collectSelectedAuditReferences = useCallback((questions: Array<Question>) => {
        let auditReferences = new Set<string>();
        questions?.forEach((q) => {
            const references = q.answer.selections.filter((x: any): x is AnswerSelection => x.selected)
                .map(s => s.auditReferences)
                .filter(s => s && s?.length > 0)
                .reduce((a, v) => v && a && a.concat(v), []);
            references?.forEach(r => r && auditReferences.add(r));
        });
        return auditReferences;
    }, []);

    const completeAuditPhase = (auditReferences: Set<string>) => {
        allocateAuditToCase(auditReferences, currentPhase)
            .then(() => Notification.success("Phase has been completed."))
            .finally(() => updateAudits());
    }

    const handleCompleteAuditPhase = () => {
        if (currentCase) {
            let auditReferences = collectSelectedAuditReferences(questions);
            if (auditReferences.size === 0) {
                setOpenConfirmReviewModal(true);
            } else {
                completeAuditPhase(auditReferences);
            }
        }
    }

    const getActiveStep = () => {
        switch (currentPhase) {
            case AuditPhase.basic:
                return 0;
            case AuditPhase.thematic:
                return 1;
            case AuditPhase.specific:
                return 2;
            default:
                return 0
        }
    }

    const updateQuestionData = (data: Question) => {
        setQuestions((prevState) => prevState.map(q => q.id === data.id ? data : q));
    }

    const renderAuditPhaseStepper = () => {
        if (currentCase) {
            return <Accordion className={styles.questionAccordion} expanded={expanded}>
                <Stack className={styles.accordionStepper}>
                    <Stepper alternativeLabel activeStep={getActiveStep()}>
                        <Step className={styles.accordionStep}>
                            <StepButton onClick={() => changeAuditsView(AuditPhase.basic)}>
                                Basis Fragen
                            </StepButton>
                        </Step>
                        <Step className={styles.accordionStep}
                              disabled={currentCase.phase !== AuditPhase.thematic && currentCase.phase !== AuditPhase.specific}>
                            <StepButton onClick={() => changeAuditsView(AuditPhase.thematic)}>
                                Thematische Fragen
                            </StepButton>
                        </Step>
                        <Step className={styles.accordionStep}
                              disabled={currentCase.phase !== AuditPhase.specific}>
                            <StepButton onClick={() => changeAuditsView(AuditPhase.specific)}>
                                Spezifische Fragen
                            </StepButton>
                        </Step>
                    </Stepper>
                </Stack>
                <AccordionDetails className={styles.accordionDetails}>
                    {loading && <div className={styles.progressContainer}>
                        <CircularProgress className={styles.circular} size={100}/>
                    </div>
                    }
                    {!loading && <>
                        <CarouselFilter items={questions} onFilterChange={updateFilteredQuestions}/>
                        <QuestionCarousel itemsPerSlide={3}
                                          title={getQuestionCarouselTitle()}
                                          action={
                                              <Button className={styles.carouselContent}
                                                      variant="contained"
                                                      disabled={filteredQuestions.length === 0 || updating}
                                                      onClick={() => handleCompleteAuditPhase()}>
                                                  {getQuestionCarouselTitle()} abschließen
                                              </Button>
                                          }
                        >
                            {filteredQuestions?.map(q =>
                                <QuestionCarouselItem key={q.id}>
                                    <AuditQuestion
                                        className={styles.carouselContent}
                                        questionData={q}
                                        onChange={(data: Question) => updateQuestionData(data)}
                                    />
                                </QuestionCarouselItem>
                            )}
                        </QuestionCarousel>
                    </>
                    }
                </AccordionDetails>
            </Accordion>
        }
        return <></>
    }

    const renderStepper = () => {
        if (currentCase && (currentCase.status === CaseStatus.open || currentCase.status === CaseStatus.inProgress))
            return renderAuditPhaseStepper();
        return <></>
    }

    const changeToReviewStatus = () => {
        if (currentCase) {
            setOpenConfirmReviewModal(false);
            completeAuditPhase(new Set<string>());
        }
    }

    return <>
        {renderStepper()}
        <Dialog className={styles.confirmDialog} open={openConfirmReviewModal} onClose={() => setOpenConfirmReviewModal(false)}>
            <DialogTitle>
                {t('content.case.dialog.confirmReview.title')}
            </DialogTitle>
            <DialogContent className={styles.confirmDialogContent}>
                <TranslatedTypography>
                    content.case.dialog.confirmReview.message
                </TranslatedTypography>
                <Alert className={styles.confirmDialogSubject} severity="info" icon={<></>}>
                    {currentCase && currentCase.name}
                </Alert>
                <Alert severity="warning">
                    <TranslatedTypography>
                        content.case.dialog.confirmReview.warning
                    </TranslatedTypography>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenConfirmReviewModal(false)} variant={"contained"} color={"secondary"}>
                    <TranslatedTypography variant={"button"}>
                        action.cancel
                    </TranslatedTypography>
                </Button>
                <Button onClick={() => changeToReviewStatus()} variant={"contained"}>
                    <TranslatedTypography variant={"button"}>
                        action.accept
                    </TranslatedTypography>
                </Button>
            </DialogActions>
        </Dialog>
    </>
}
