/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Audit = {
  __typename: "Audit",
  id: string,
  caseID?: string | null,
  name: string,
  description: string,
  phase: AuditPhase,
  originAuditID?: string | null,
  isActive: boolean,
  isTemplate: boolean,
  case?: Case | null,
  questions?: ModelQuestionConnection | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum AuditPhase {
  basic = "basic",
  thematic = "thematic",
  specific = "specific",
}


export type Case = {
  __typename: "Case",
  id: string,
  name: string,
  description: string,
  phase: AuditPhase,
  status: CaseStatus,
  costUnit?: string | null,
  riscOfficer?: string | null,
  projectResponsible?: string | null,
  projectOwner?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  audits?: ModelAuditConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum CaseStatus {
  open = "open",
  inProgress = "inProgress",
  inReview = "inReview",
  completed = "completed",
  cancelled = "cancelled",
  inRecertification = "inRecertification",
}


export type ModelAuditConnection = {
  __typename: "ModelAuditConnection",
  items:  Array<Audit | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  auditID: string,
  question: string,
  answer: Answer,
  favourite?: boolean | null,
  description?: string | null,
  audit?: Audit | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
  editors?: string | null,
};

export type Answer = {
  __typename: "Answer",
  type: AnswerType,
  selections:  Array<AnswerSelection >,
};

export enum AnswerType {
  radio = "radio",
  text = "text",
  check = "check",
}


export type AnswerSelection = {
  __typename: "AnswerSelection",
  selected: boolean,
  value: string,
  auditReferences: Array< string | null >,
};

export type ModelAuditFilterInput = {
  id?: ModelIDInput | null,
  caseID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  originAuditID?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  isTemplate?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelAuditFilterInput | null > | null,
  or?: Array< ModelAuditFilterInput | null > | null,
  not?: ModelAuditFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelAuditPhaseInput = {
  eq?: AuditPhase | null,
  ne?: AuditPhase | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type CreateCaseInput = {
  id?: string | null,
  name: string,
  description: string,
  phase: AuditPhase,
  status: CaseStatus,
  costUnit?: string | null,
  riscOfficer?: string | null,
  projectResponsible?: string | null,
  projectOwner?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelCaseConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  status?: ModelCaseStatusInput | null,
  costUnit?: ModelStringInput | null,
  riscOfficer?: ModelStringInput | null,
  projectResponsible?: ModelStringInput | null,
  projectOwner?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelCaseConditionInput | null > | null,
  or?: Array< ModelCaseConditionInput | null > | null,
  not?: ModelCaseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCaseStatusInput = {
  eq?: CaseStatus | null,
  ne?: CaseStatus | null,
};

export type UpdateCaseInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  phase?: AuditPhase | null,
  status?: CaseStatus | null,
  costUnit?: string | null,
  riscOfficer?: string | null,
  projectResponsible?: string | null,
  projectOwner?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteCaseInput = {
  id: string,
  _version?: number | null,
};

export type CreateAuditInput = {
  id?: string | null,
  caseID?: string | null,
  name: string,
  description: string,
  phase: AuditPhase,
  originAuditID?: string | null,
  isActive: boolean,
  isTemplate: boolean,
  owner?: string | null,
  editors?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelAuditConditionInput = {
  caseID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  originAuditID?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  isTemplate?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelAuditConditionInput | null > | null,
  or?: Array< ModelAuditConditionInput | null > | null,
  not?: ModelAuditConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateAuditInput = {
  id: string,
  caseID?: string | null,
  name?: string | null,
  description?: string | null,
  phase?: AuditPhase | null,
  originAuditID?: string | null,
  isActive?: boolean | null,
  isTemplate?: boolean | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteAuditInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionInput = {
  id?: string | null,
  auditID: string,
  question: string,
  answer: AnswerInput,
  favourite?: boolean | null,
  description?: string | null,
  _version?: number | null,
};

export type AnswerInput = {
  type: AnswerType,
  selections: Array< AnswerSelectionInput >,
};

export type AnswerSelectionInput = {
  selected: boolean,
  value: string,
  auditReferences: Array< string | null >,
};

export type ModelQuestionConditionInput = {
  auditID?: ModelIDInput | null,
  question?: ModelStringInput | null,
  favourite?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateQuestionInput = {
  id: string,
  auditID?: string | null,
  question?: string | null,
  answer?: AnswerInput | null,
  favourite?: boolean | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteQuestionInput = {
  id: string,
  _version?: number | null,
};

export type CreateArticleInput = {
  id?: string | null,
  title: string,
  introduction: string,
  content: string,
  thumbnail?: string | null,
  active: boolean,
  pinned: boolean,
  topics: Array< string >,
  _version?: number | null,
};

export type ModelArticleConditionInput = {
  title?: ModelStringInput | null,
  introduction?: ModelStringInput | null,
  content?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  pinned?: ModelBooleanInput | null,
  topics?: ModelStringInput | null,
  and?: Array< ModelArticleConditionInput | null > | null,
  or?: Array< ModelArticleConditionInput | null > | null,
  not?: ModelArticleConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Article = {
  __typename: "Article",
  id: string,
  title: string,
  introduction: string,
  content: string,
  thumbnail?: string | null,
  active: boolean,
  pinned: boolean,
  topics: Array< string >,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateArticleInput = {
  id: string,
  title?: string | null,
  introduction?: string | null,
  content?: string | null,
  thumbnail?: string | null,
  active?: boolean | null,
  pinned?: boolean | null,
  topics?: Array< string > | null,
  _version?: number | null,
};

export type DeleteArticleInput = {
  id: string,
  _version?: number | null,
};

export type SearchableArticleFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  introduction?: SearchableStringFilterInput | null,
  content?: SearchableStringFilterInput | null,
  thumbnail?: SearchableStringFilterInput | null,
  active?: SearchableBooleanFilterInput | null,
  pinned?: SearchableBooleanFilterInput | null,
  topics?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableArticleFilterInput | null > | null,
  or?: Array< SearchableArticleFilterInput | null > | null,
  not?: SearchableArticleFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableArticleSortInput = {
  field?: SearchableArticleSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableArticleSortableFields {
  id = "id",
  title = "title",
  introduction = "introduction",
  content = "content",
  thumbnail = "thumbnail",
  active = "active",
  pinned = "pinned",
  topics = "topics",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableArticleAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableArticleAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableArticleAggregateField {
  id = "id",
  title = "title",
  introduction = "introduction",
  content = "content",
  thumbnail = "thumbnail",
  active = "active",
  pinned = "pinned",
  topics = "topics",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableArticleConnection = {
  __typename: "SearchableArticleConnection",
  items:  Array<Article | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelCaseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  phase?: ModelAuditPhaseInput | null,
  status?: ModelCaseStatusInput | null,
  costUnit?: ModelStringInput | null,
  riscOfficer?: ModelStringInput | null,
  projectResponsible?: ModelStringInput | null,
  projectOwner?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelCaseFilterInput | null > | null,
  or?: Array< ModelCaseFilterInput | null > | null,
  not?: ModelCaseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCaseConnection = {
  __typename: "ModelCaseConnection",
  items:  Array<Case | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  auditID?: ModelIDInput | null,
  question?: ModelStringInput | null,
  favourite?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelArticleFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  introduction?: ModelStringInput | null,
  content?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  pinned?: ModelBooleanInput | null,
  topics?: ModelStringInput | null,
  and?: Array< ModelArticleFilterInput | null > | null,
  or?: Array< ModelArticleFilterInput | null > | null,
  not?: ModelArticleFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelArticleConnection = {
  __typename: "ModelArticleConnection",
  items:  Array<Article | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelSubscriptionCaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  phase?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  costUnit?: ModelSubscriptionStringInput | null,
  riscOfficer?: ModelSubscriptionStringInput | null,
  projectResponsible?: ModelSubscriptionStringInput | null,
  projectOwner?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionCaseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionAuditFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  caseID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  phase?: ModelSubscriptionStringInput | null,
  originAuditID?: ModelSubscriptionIDInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  isTemplate?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionAuditFilterInput | null > | null,
  or?: Array< ModelSubscriptionAuditFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  auditID?: ModelSubscriptionIDInput | null,
  question?: ModelSubscriptionStringInput | null,
  favourite?: ModelSubscriptionBooleanInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionArticleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  introduction?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  pinned?: ModelSubscriptionBooleanInput | null,
  topics?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionArticleFilterInput | null > | null,
  or?: Array< ModelSubscriptionArticleFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type GetCustomAuditQueryVariables = {
  id: string,
};

export type GetCustomAuditQuery = {
  getAudit?:  {
    __typename: "Audit",
    name: string,
    description: string,
    phase: AuditPhase,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        question: string,
        description?: string | null,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
          selections:  Array< {
            __typename: "AnswerSelection",
            value: string,
            selected: boolean,
            auditReferences: Array< string | null >,
          } >,
        },
        _deleted?: boolean | null,
      } | null >,
    } | null,
  } | null,
};

export type listAuditsWithBasicDataQueryVariables = {
  filter?: ModelAuditFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listAuditsWithBasicDataQuery = {
  listAudits?:  {
    __typename: "ModelAuditConnection",
    items:  Array< {
      __typename: "Audit",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CreateCaseMutationVariables = {
  input: CreateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type CreateCaseMutation = {
  createCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    description: string,
    phase: AuditPhase,
    status: CaseStatus,
    costUnit?: string | null,
    riscOfficer?: string | null,
    projectResponsible?: string | null,
    projectOwner?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCaseMutationVariables = {
  input: UpdateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type UpdateCaseMutation = {
  updateCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    description: string,
    phase: AuditPhase,
    status: CaseStatus,
    costUnit?: string | null,
    riscOfficer?: string | null,
    projectResponsible?: string | null,
    projectOwner?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCaseMutationVariables = {
  input: DeleteCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type DeleteCaseMutation = {
  deleteCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    description: string,
    phase: AuditPhase,
    status: CaseStatus,
    costUnit?: string | null,
    riscOfficer?: string | null,
    projectResponsible?: string | null,
    projectOwner?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAuditMutationVariables = {
  input: CreateAuditInput,
  condition?: ModelAuditConditionInput | null,
};

export type CreateAuditMutation = {
  createAudit?:  {
    __typename: "Audit",
    id: string,
    caseID?: string | null,
    name: string,
    description: string,
    phase: AuditPhase,
    originAuditID?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        auditID: string,
        question: string,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
        },
        favourite?: boolean | null,
        description?: string | null,
        audit?:  {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAuditMutationVariables = {
  input: UpdateAuditInput,
  condition?: ModelAuditConditionInput | null,
};

export type UpdateAuditMutation = {
  updateAudit?:  {
    __typename: "Audit",
    id: string,
    caseID?: string | null,
    name: string,
    description: string,
    phase: AuditPhase,
    originAuditID?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        auditID: string,
        question: string,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
        },
        favourite?: boolean | null,
        description?: string | null,
        audit?:  {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAuditMutationVariables = {
  input: DeleteAuditInput,
  condition?: ModelAuditConditionInput | null,
};

export type DeleteAuditMutation = {
  deleteAudit?:  {
    __typename: "Audit",
    id: string,
    caseID?: string | null,
    name: string,
    description: string,
    phase: AuditPhase,
    originAuditID?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        auditID: string,
        question: string,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
        },
        favourite?: boolean | null,
        description?: string | null,
        audit?:  {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    auditID: string,
    question: string,
    answer:  {
      __typename: "Answer",
      type: AnswerType,
      selections:  Array< {
        __typename: "AnswerSelection",
        selected: boolean,
        value: string,
        auditReferences: Array< string | null >,
      } >,
    },
    favourite?: boolean | null,
    description?: string | null,
    audit?:  {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    auditID: string,
    question: string,
    answer:  {
      __typename: "Answer",
      type: AnswerType,
      selections:  Array< {
        __typename: "AnswerSelection",
        selected: boolean,
        value: string,
        auditReferences: Array< string | null >,
      } >,
    },
    favourite?: boolean | null,
    description?: string | null,
    audit?:  {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    auditID: string,
    question: string,
    answer:  {
      __typename: "Answer",
      type: AnswerType,
      selections:  Array< {
        __typename: "AnswerSelection",
        selected: boolean,
        value: string,
        auditReferences: Array< string | null >,
      } >,
    },
    favourite?: boolean | null,
    description?: string | null,
    audit?:  {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type CreateArticleMutationVariables = {
  input: CreateArticleInput,
  condition?: ModelArticleConditionInput | null,
};

export type CreateArticleMutation = {
  createArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    introduction: string,
    content: string,
    thumbnail?: string | null,
    active: boolean,
    pinned: boolean,
    topics: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateArticleMutationVariables = {
  input: UpdateArticleInput,
  condition?: ModelArticleConditionInput | null,
};

export type UpdateArticleMutation = {
  updateArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    introduction: string,
    content: string,
    thumbnail?: string | null,
    active: boolean,
    pinned: boolean,
    topics: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteArticleMutationVariables = {
  input: DeleteArticleInput,
  condition?: ModelArticleConditionInput | null,
};

export type DeleteArticleMutation = {
  deleteArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    introduction: string,
    content: string,
    thumbnail?: string | null,
    active: boolean,
    pinned: boolean,
    topics: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type SearchArticlesQueryVariables = {
  filter?: SearchableArticleFilterInput | null,
  sort?: Array< SearchableArticleSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableArticleAggregationInput | null > | null,
};

export type SearchArticlesQuery = {
  searchArticles?:  {
    __typename: "SearchableArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      introduction: string,
      content: string,
      thumbnail?: string | null,
      active: boolean,
      pinned: boolean,
      topics: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCaseQueryVariables = {
  id: string,
};

export type GetCaseQuery = {
  getCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    description: string,
    phase: AuditPhase,
    status: CaseStatus,
    costUnit?: string | null,
    riscOfficer?: string | null,
    projectResponsible?: string | null,
    projectOwner?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCasesQueryVariables = {
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesQuery = {
  listCases?:  {
    __typename: "ModelCaseConnection",
    items:  Array< {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCasesQueryVariables = {
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCasesQuery = {
  syncCases?:  {
    __typename: "ModelCaseConnection",
    items:  Array< {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAuditQueryVariables = {
  id: string,
};

export type GetAuditQuery = {
  getAudit?:  {
    __typename: "Audit",
    id: string,
    caseID?: string | null,
    name: string,
    description: string,
    phase: AuditPhase,
    originAuditID?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        auditID: string,
        question: string,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
        },
        favourite?: boolean | null,
        description?: string | null,
        audit?:  {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAuditsQueryVariables = {
  filter?: ModelAuditFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuditsQuery = {
  listAudits?:  {
    __typename: "ModelAuditConnection",
    items:  Array< {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAuditsQueryVariables = {
  filter?: ModelAuditFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAuditsQuery = {
  syncAudits?:  {
    __typename: "ModelAuditConnection",
    items:  Array< {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    auditID: string,
    question: string,
    answer:  {
      __typename: "Answer",
      type: AnswerType,
      selections:  Array< {
        __typename: "AnswerSelection",
        selected: boolean,
        value: string,
        auditReferences: Array< string | null >,
      } >,
    },
    favourite?: boolean | null,
    description?: string | null,
    audit?:  {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      auditID: string,
      question: string,
      answer:  {
        __typename: "Answer",
        type: AnswerType,
        selections:  Array< {
          __typename: "AnswerSelection",
          selected: boolean,
          value: string,
          auditReferences: Array< string | null >,
        } >,
      },
      favourite?: boolean | null,
      description?: string | null,
      audit?:  {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
      editors?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsQuery = {
  syncQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      auditID: string,
      question: string,
      answer:  {
        __typename: "Answer",
        type: AnswerType,
        selections:  Array< {
          __typename: "AnswerSelection",
          selected: boolean,
          value: string,
          auditReferences: Array< string | null >,
        } >,
      },
      favourite?: boolean | null,
      description?: string | null,
      audit?:  {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
      editors?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetArticleQueryVariables = {
  id: string,
};

export type GetArticleQuery = {
  getArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    introduction: string,
    content: string,
    thumbnail?: string | null,
    active: boolean,
    pinned: boolean,
    topics: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListArticlesQueryVariables = {
  filter?: ModelArticleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticlesQuery = {
  listArticles?:  {
    __typename: "ModelArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      introduction: string,
      content: string,
      thumbnail?: string | null,
      active: boolean,
      pinned: boolean,
      topics: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncArticlesQueryVariables = {
  filter?: ModelArticleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncArticlesQuery = {
  syncArticles?:  {
    __typename: "ModelArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      introduction: string,
      content: string,
      thumbnail?: string | null,
      active: boolean,
      pinned: boolean,
      topics: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AuditsByCaseIDQueryVariables = {
  caseID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAuditFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AuditsByCaseIDQuery = {
  auditsByCaseID?:  {
    __typename: "ModelAuditConnection",
    items:  Array< {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type QuestionsByAuditIDQueryVariables = {
  auditID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByAuditIDQuery = {
  questionsByAuditID?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      auditID: string,
      question: string,
      answer:  {
        __typename: "Answer",
        type: AnswerType,
        selections:  Array< {
          __typename: "AnswerSelection",
          selected: boolean,
          value: string,
          auditReferences: Array< string | null >,
        } >,
      },
      favourite?: boolean | null,
      description?: string | null,
      audit?:  {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
      editors?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateCaseSubscriptionVariables = {
  filter?: ModelSubscriptionCaseFilterInput | null,
  owner?: string | null,
};

export type OnCreateCaseSubscription = {
  onCreateCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    description: string,
    phase: AuditPhase,
    status: CaseStatus,
    costUnit?: string | null,
    riscOfficer?: string | null,
    projectResponsible?: string | null,
    projectOwner?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCaseSubscriptionVariables = {
  filter?: ModelSubscriptionCaseFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCaseSubscription = {
  onUpdateCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    description: string,
    phase: AuditPhase,
    status: CaseStatus,
    costUnit?: string | null,
    riscOfficer?: string | null,
    projectResponsible?: string | null,
    projectOwner?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCaseSubscriptionVariables = {
  filter?: ModelSubscriptionCaseFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCaseSubscription = {
  onDeleteCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    description: string,
    phase: AuditPhase,
    status: CaseStatus,
    costUnit?: string | null,
    riscOfficer?: string | null,
    projectResponsible?: string | null,
    projectOwner?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    audits?:  {
      __typename: "ModelAuditConnection",
      items:  Array< {
        __typename: "Audit",
        id: string,
        caseID?: string | null,
        name: string,
        description: string,
        phase: AuditPhase,
        originAuditID?: string | null,
        isActive: boolean,
        isTemplate: boolean,
        case?:  {
          __typename: "Case",
          id: string,
          name: string,
          description: string,
          phase: AuditPhase,
          status: CaseStatus,
          costUnit?: string | null,
          riscOfficer?: string | null,
          projectResponsible?: string | null,
          projectOwner?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        questions?:  {
          __typename: "ModelQuestionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAuditSubscriptionVariables = {
  filter?: ModelSubscriptionAuditFilterInput | null,
  owner?: string | null,
};

export type OnCreateAuditSubscription = {
  onCreateAudit?:  {
    __typename: "Audit",
    id: string,
    caseID?: string | null,
    name: string,
    description: string,
    phase: AuditPhase,
    originAuditID?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        auditID: string,
        question: string,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
        },
        favourite?: boolean | null,
        description?: string | null,
        audit?:  {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAuditSubscriptionVariables = {
  filter?: ModelSubscriptionAuditFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAuditSubscription = {
  onUpdateAudit?:  {
    __typename: "Audit",
    id: string,
    caseID?: string | null,
    name: string,
    description: string,
    phase: AuditPhase,
    originAuditID?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        auditID: string,
        question: string,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
        },
        favourite?: boolean | null,
        description?: string | null,
        audit?:  {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAuditSubscriptionVariables = {
  filter?: ModelSubscriptionAuditFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAuditSubscription = {
  onDeleteAudit?:  {
    __typename: "Audit",
    id: string,
    caseID?: string | null,
    name: string,
    description: string,
    phase: AuditPhase,
    originAuditID?: string | null,
    isActive: boolean,
    isTemplate: boolean,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      description: string,
      phase: AuditPhase,
      status: CaseStatus,
      costUnit?: string | null,
      riscOfficer?: string | null,
      projectResponsible?: string | null,
      projectOwner?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      audits?:  {
        __typename: "ModelAuditConnection",
        items:  Array< {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        auditID: string,
        question: string,
        answer:  {
          __typename: "Answer",
          type: AnswerType,
        },
        favourite?: boolean | null,
        description?: string | null,
        audit?:  {
          __typename: "Audit",
          id: string,
          caseID?: string | null,
          name: string,
          description: string,
          phase: AuditPhase,
          originAuditID?: string | null,
          isActive: boolean,
          isTemplate: boolean,
          owner?: string | null,
          editors?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    auditID: string,
    question: string,
    answer:  {
      __typename: "Answer",
      type: AnswerType,
      selections:  Array< {
        __typename: "AnswerSelection",
        selected: boolean,
        value: string,
        auditReferences: Array< string | null >,
      } >,
    },
    favourite?: boolean | null,
    description?: string | null,
    audit?:  {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnUpdateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    auditID: string,
    question: string,
    answer:  {
      __typename: "Answer",
      type: AnswerType,
      selections:  Array< {
        __typename: "AnswerSelection",
        selected: boolean,
        value: string,
        auditReferences: Array< string | null >,
      } >,
    },
    favourite?: boolean | null,
    description?: string | null,
    audit?:  {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnDeleteQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    auditID: string,
    question: string,
    answer:  {
      __typename: "Answer",
      type: AnswerType,
      selections:  Array< {
        __typename: "AnswerSelection",
        selected: boolean,
        value: string,
        auditReferences: Array< string | null >,
      } >,
    },
    favourite?: boolean | null,
    description?: string | null,
    audit?:  {
      __typename: "Audit",
      id: string,
      caseID?: string | null,
      name: string,
      description: string,
      phase: AuditPhase,
      originAuditID?: string | null,
      isActive: boolean,
      isTemplate: boolean,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        description: string,
        phase: AuditPhase,
        status: CaseStatus,
        costUnit?: string | null,
        riscOfficer?: string | null,
        projectResponsible?: string | null,
        projectOwner?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        audits?:  {
          __typename: "ModelAuditConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          auditID: string,
          question: string,
          favourite?: boolean | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnCreateArticleSubscriptionVariables = {
  filter?: ModelSubscriptionArticleFilterInput | null,
};

export type OnCreateArticleSubscription = {
  onCreateArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    introduction: string,
    content: string,
    thumbnail?: string | null,
    active: boolean,
    pinned: boolean,
    topics: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateArticleSubscriptionVariables = {
  filter?: ModelSubscriptionArticleFilterInput | null,
};

export type OnUpdateArticleSubscription = {
  onUpdateArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    introduction: string,
    content: string,
    thumbnail?: string | null,
    active: boolean,
    pinned: boolean,
    topics: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteArticleSubscriptionVariables = {
  filter?: ModelSubscriptionArticleFilterInput | null,
};

export type OnDeleteArticleSubscription = {
  onDeleteArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    introduction: string,
    content: string,
    thumbnail?: string | null,
    active: boolean,
    pinned: boolean,
    topics: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
