import React from 'react';
import styles from "./styles/styles.module.scss"
import {UserContextProvider} from "./context/UserContext";
import {Notification} from "./helpers/notification/Notification";
import "./styles/styles.scss"
import "./styles/article-styles.css"
import {ThemeProvider} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

import {theme} from "./styles/theme";
import {i18nsetup} from "./locale/i18nConfiguration";
import dayjs from "dayjs";

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime';
import i18next from "i18next";
import 'dayjs/locale/de';
import {AppRouter} from "./AppRouter";
import {PortalLayout} from "./layouts/portal-layout/PortalLayout";

i18nsetup();
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.locale(i18next.resolvedLanguage);

function App() {
    return <UserContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <div className={styles.appContent}>
                    <AppRouter layout={<PortalLayout/>}/>
                </div>
                <Notification debug/>
            </ThemeProvider>
        </LocalizationProvider>
    </UserContextProvider>
}

export default App;