import {API, graphqlOperation, GraphQLQuery} from "@aws-amplify/api";
import {CreateQuestionInput, CreateQuestionMutation, DeleteQuestionInput, DeleteQuestionMutation, Question, QuestionsByAuditIDQuery, UpdateQuestionInput, UpdateQuestionMutation} from "../../API";
import {useUserToken} from "../../context/UserContext";
import {useCallback} from "react";
import {questionsByAuditID} from "../../graphql/queries";
import {createQuestion as createQuestionOperation, deleteQuestion as deleteQuestionOperation, updateQuestion as updateQuestionOperation} from "../../graphql/mutations";
import _ from "lodash";


export const useQuestionService = () => {
    const {getJwtToken} = useUserToken();

    const createQuestion = useCallback(async (input: CreateQuestionInput): Promise<Question> => {
        const token = await getJwtToken();
        const response = await API.graphql<GraphQLQuery<CreateQuestionMutation>>(graphqlOperation(createQuestionOperation, {input: input}, token))
        return response.data?.createQuestion as Question
    }, [getJwtToken]);

    const createQuestions = useCallback(async (questions: Array<Question | null>, auditId: string): Promise<void> => {
        for (let question of questions) {
            if (question && !question._deleted) {
                question.auditID = auditId;
                const tempQuestion = _.omit(question, ["_deleted"]);
                await createQuestion(tempQuestion);
            }
        }
    }, [createQuestion]);

    const deleteQuestion = useCallback(async (input: DeleteQuestionInput): Promise<Question> => {
        const token = await getJwtToken();
        const response = await API.graphql<GraphQLQuery<DeleteQuestionMutation>>(graphqlOperation(deleteQuestionOperation, {input: input}, token))
        return response.data?.deleteQuestion as Question
    }, [getJwtToken]);

    const updateQuestion = useCallback(async (input: UpdateQuestionInput): Promise<Question> => {
        const token = await getJwtToken();
        const response = await API.graphql<GraphQLQuery<UpdateQuestionMutation>>(graphqlOperation(updateQuestionOperation, {input: input}, token))
        return response.data?.updateQuestion as Question
    }, [getJwtToken]);

    const listQuestionsByAuditId = useCallback(async (auditId: string): Promise<Array<Question>> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<QuestionsByAuditIDQuery>>(graphqlOperation(questionsByAuditID, {auditID: auditId}, token))
        return data?.questionsByAuditID?.items.filter((a): a is Question => a != null).filter(q => !q._deleted) as Array<Question>;
    }, [getJwtToken]);

    return {listQuestionsByAuditId, updateQuestion, createQuestion, createQuestions, deleteQuestion}
}

