import {Case, CreateCaseInput, CreateCaseMutation, GetCaseQuery, ListCasesQuery, UpdateCaseInput, UpdateCaseMutation} from "../../API";
import {API, GRAPHQL_AUTH_MODE, graphqlOperation, GraphQLQuery} from "@aws-amplify/api";
import {createCase as persistCase, updateCase as persistUpdateCase} from "../../graphql/mutations";
import {useUserToken} from "../../context/UserContext";
import {getCase, listCases as listCasesOperation} from "../../graphql/queries";
import {useAuditService} from "./AuditService";
import {useCallback} from "react";


export const useCaseService = () => {
    const {getJwtToken} = useUserToken();
    const {appendTemplateAudits} = useAuditService();

    const listCases = useCallback(async (): Promise<Array<Case>> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<ListCasesQuery>>(graphqlOperation(listCasesOperation, {}, token));
        return data?.listCases?.items as Array<Case>;
    }, [getJwtToken])

    const getCaseById = useCallback(async (caseId: string): Promise<Case> => {
        const token = await getJwtToken();
        const result = await API.graphql<GraphQLQuery<GetCaseQuery>>(graphqlOperation(getCase, {id: caseId}, token));
        return result.data?.getCase as Case;
    }, [getJwtToken])

    const createCase = useCallback(async (caseData: CreateCaseInput) => {
        const token = await getJwtToken();
        const result = await API.graphql<GraphQLQuery<CreateCaseMutation>>({
            query: persistCase,
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: token,
            variables: {input: caseData}
        })
        return result.data?.createCase as Case;
    }, [getJwtToken]);

    const createCaseWithTemplateAudits = useCallback(async (caseData: CreateCaseInput): Promise<Case> => {
        return await createCase(caseData).then((c: Case) => appendTemplateAudits(c.id).then(() => Promise.resolve(c)));
    }, [appendTemplateAudits, createCase]);

    const updateCase = useCallback(async (caseData: UpdateCaseInput): Promise<Case> => {
        const token = await getJwtToken();
        const result = await API.graphql<GraphQLQuery<UpdateCaseMutation>>({
            query: persistUpdateCase,
            authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
            authToken: token,
            variables: {input: caseData}
        });
        return result.data?.updateCase as Case;
    }, [getJwtToken]);

    return {updateCase, getCaseById, createCase, createCaseWithTemplateAudits, listCases}
}