import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import styles from "./portal-layout.module.scss"
import {useUserToken} from "../../context/UserContext";
import {AppSideNav} from "../../components/sidenav/AppSideNav";
import {AppToolbar} from "../../components/toolbar/AppToolbar";
import {Footer} from "../../components/footer/Footer";
import {Backdrop, CircularProgress} from "@mui/material";

export const PortalLayout = () => {
    const {getJwtToken} = useUserToken();
    const [token, setToken] = useState<string>();

    useEffect(() => {
        getJwtToken().then(t => setToken(t));
    }, [getJwtToken])

    const renderLayout =  () => {
        if (token) {
            return <>
                <div className={styles.headerContainer}>
                    <AppToolbar/>
                </div>
                <div className={styles.sidenavContainer}>
                    <AppSideNav/>
                </div>
                <div className={styles.mainContainer}>
                    <Outlet/>
                    <Footer/>
                </div>
            </>
        }
        return <Backdrop open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    }
    return renderLayout()
}
