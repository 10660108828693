import {AnswerInput, Case, Question, UpdateQuestionInput} from "../../../API";
import AuditAnswer from "../audit-answer/AuditAnswer";
import React, {ReducerState, useReducer, useState} from "react";
import {Notification} from "../../../helpers/notification/Notification";
import classNames from "classnames";

import styles from "./carousel/question-carousel.module.scss";
import {Card, CardActions, CardContent, CardHeader, Chip, Fade, IconButton, Popper, TextField, Typography} from "@mui/material";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import InfoIcon from "@mui/icons-material/Info";
import {useQuestionService} from "../../../hooks/services/QuestionService";
import {useCaseData} from "../../../context/CaseContext";
import {useCaseService} from "../../../hooks/services/CaseService";
import {useTranslation} from "react-i18next";

type AuditQuestionProps = {
    questionData?: Question,
    className: any,
    editMode?: boolean | undefined,
    onChange: Function,
    onCreate?: Function,
    onDelete?: Function,
}

const stateReducer = (state: Question, action: Question | any) => ({
    ...state,
    ...(typeof action === "function" ? action(state) : action),
});

function AuditQuestion(props: AuditQuestionProps) {
    const {questionData, className} = props;
    const {onChange} = props;
    const {t} = useTranslation();
    const {setUpdating, currentCase, currentPhase, setCase} = useCaseData();
    const {updateCase} = useCaseService();
    const {updateQuestion} = useQuestionService();
    const [question, setQuestion] = useReducer(stateReducer, questionData as ReducerState<any>);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
    const [arrowEl, setArrowEl] = React.useState<HTMLElement | null>();

    const updateAuditQuestion = (updateQuestionInput: UpdateQuestionInput) => {
        updateQuestion(updateQuestionInput)
            .then((d) => {
                setQuestion(d);
                onChange(d);
            })
            .then(() => Notification.success("Question updated."))
            .then(() => {
                if (currentCase && currentCase?.phase !== currentPhase) {
                    updateCase({
                        id: currentCase.id,
                        _version: currentCase._version,
                        phase: currentPhase
                    }).then((c: Case) => setCase(c));
                }
            })
            .catch(() => {
                Notification.error("Could not update question information.");
            })
            .finally(() => setUpdating(false));
    }

    const renderLastUpdate = () => {
        if (question?.updatedAt) {
            let date = new Date(question.updatedAt);
            return <Chip className={styles.cardChip} label={"Aktualisiert am: " + date.toLocaleString()}/>
        }
    }

    const renderAuditToolbar = () => {
        return <div>
            <IconButton onClick={() => setShowInfo(!showInfo)} color={"info"} ref={(r) => setAnchorEl(r)}>
                <InfoIcon color={showInfo ? "warning" : "info"}/>
            </IconButton>
            <Popper id={question.id}
                    className={styles.popper}
                    open={showInfo}
                    anchorEl={anchorEl}
                    transition
                    placement={"bottom-end"}
                    disablePortal={false}
                    modifiers={[
                        {
                            name: "arrow",
                            enabled: true,
                            options: {
                                element: arrowEl,
                            }
                        }
                    ]}>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={500}>
                        <div>
                            <span className={styles.arrow} ref={(e) => setArrowEl(e)}/>
                            <Card variant={"outlined"}>
                                <CardHeader title={t("content.common.questionnaire")}
                                            subheader={question.audit.name}
                                            titleTypographyProps={{variant: "body1"}}
                                />
                                <CardContent>
                                    <TextField multiline disabled value={question.description} fullWidth/>
                                </CardContent>
                                <CardActions>
                                    {renderLastUpdate()}
                                </CardActions>
                            </Card>
                        </div>
                    </Fade>
                )}
            </Popper>
            <IconButton
                onClick={() => updateAuditQuestion({
                    _version: question?._version,
                    favourite: !question?.favourite,
                    id: question?.id,
                })}>
                <TurnedInIcon className={question?.favourite ? classNames(styles.favouriteIconSelected, styles.acapIcon) : classNames(styles.favouriteIconDeselected, styles.acapIcon)}/>
            </IconButton>
        </div>
    }

    const renderQuestion = () => {
        return <Card className={classNames(className, styles.card)}>
            <CardHeader action={renderAuditToolbar()}/>
            <CardContent className={styles.cardContainer}>
                <div className={styles.cardColumn}>
                    <div className={styles.cardRow}>
                        <Typography>
                            {question.question}
                        </Typography>
                    </div>
                    <div className={styles.cardRow}>
                        <AuditAnswer answer={question.answer}
                                     onChange={
                                         (a: AnswerInput) => updateAuditQuestion({
                                             _version: question._version,
                                             answer: a,
                                             id: question.id,
                                         })
                                     }
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    }

    return renderQuestion();
}

export default AuditQuestion;

