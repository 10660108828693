import React, {useCallback, useEffect, useRef, useState} from "react";

import {Audit, AuditPhase, CreateAuditInput, Question, UpdateAuditInput} from "../../../API";
import {Notification} from "../../../helpers/notification/Notification";
import {isNotBlank} from "../../../helpers/CommonUtils";
import QuestionCarousel, {QuestionCarouselType} from "../audit-question/carousel/QuestionCarousel";
import styles from "styles/styles.module.scss";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import classNames from "classnames";
import {AuditEditorContext} from "../../../context/AuditEditorContext";
import {QuestionCarouselItem} from "../audit-question/carousel/QuestionCarouselItem";
import {useQuestionService} from "../../../hooks/services/QuestionService";
import AuditQuestionEditor from "../audit-question/AuditQuestionEditor";
import {CarouselFilter} from "../audit-question/carousel/CarouselFilter";

import AddIcon from '@mui/icons-material/Add';
import {TranslatedTypography} from "../../common/TranslatedTypography";
import {useAuditService} from "../../../hooks/services/AuditService";

type AuditCardProps = {
    auditId?: string,
    onCreate?: Function,
}

function AuditEditor(props: AuditCardProps) {
    const {auditId, onCreate} = props;
    const {getAudit, createAudit, updateAudit} = useAuditService();
    const [audit, setAudit] = useState<Audit>();
    const [auditName, setAuditName] = useState<string>("");
    const [auditPhase, setAuditPhase] = useState<AuditPhase>(AuditPhase.basic);
    const [auditDescription, setAuditDescription] = useState<string>("");
    const [auditIsActive, setAuditIsActive] = useState<boolean>(true);
    const [auditIsTemplate, setAuditIsTemplate] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [filteredQuestions, setFilteredQuestions] = useState<Array<Question>>([]);
    const [questions, setQuestions] = useState<Array<Question>>([]);
    const {listQuestionsByAuditId} = useQuestionService();
    const [appendQuestion, setAppendQuestion] = useState<boolean>(false);
    const ref = useRef<QuestionCarouselType>(null);

    useEffect(() => {
        if (auditId) {
            setLoading(true);
            listQuestionsByAuditId(auditId)
                .then(q => setQuestions(q))
                .catch((e) => Notification.error("Could not get question information.", e))
                .finally(() => setLoading(false));
        } else {
            setQuestions([]);
        }
    }, [auditId, listQuestionsByAuditId])

    useEffect(() => {
        if (auditId) {
            setLoading(true);
            getAudit(auditId)
                .then(a => {
                    setAudit(a);
                    setAuditPhase(a.phase)
                    setAuditName(a.name)
                    setAuditDescription(a.description)
                    setAuditIsActive(a.isActive)
                    setAuditIsTemplate(a.isTemplate)
                })
                .catch(() => Notification.error("Could not get question information."))
                .finally(() => setLoading(false));
        }
    }, [auditId, getAudit]);

    const validateInput = () => {
        return isNotBlank(auditName) && isNotBlank(auditDescription) && isNotBlank(auditPhase);
    }

    const getAuditData = () => {
        if (validateInput())
            return {
                id: audit?.id,
                name: auditName,
                description: auditDescription,
                phase: auditPhase,
                isActive: auditIsActive,
                isTemplate: auditIsTemplate,
                _version: audit?._version
            }
        Notification.error("Input is not valid. Please insert at least a name and a description.")
        return null;
    }

    const createNewAudit = () => {
        const auditData = getAuditData() as CreateAuditInput
        if (auditData) {
            setLoading(true);
            createAudit(auditData)
                .then(a => {
                    if (a && onCreate) {
                        onCreate(a);
                    }
                })
                .then(() => Notification.success("Audit created."))
                .catch((e) => Notification.error("Audit could not be created.", e))
                .finally(() => setLoading(false));
        }
    }

    const updateAuditData = () => {
        const auditData = getAuditData() as UpdateAuditInput
        if (auditData)
            updateAudit(auditData)
                .then(() => Notification.success("Audit created."))
                .catch(() => Notification.error("Audit could not be created"));
    }

    const copyValue = () => {
        if (audit) {
            navigator.clipboard.writeText(audit?.id as string)
                .then(() => Notification.success("Value copied to clipboard."));
        }
    }

    const updateFilteredQuestions = useCallback((out: Array<Question>) => setFilteredQuestions(out), [])

    const renderActions = () => {
        if (!audit) {
            return <Button variant="contained" onClick={createNewAudit}>
                <TranslatedTypography variant={"button"} count={1}>action.create</TranslatedTypography>
            </Button>
        }
        return <Button variant="contained" onClick={updateAuditData}>
            <TranslatedTypography variant={"button"} count={1}>action.update</TranslatedTypography>
        </Button>
    }

    const updateQuestionData = (data: Question) => {
        setQuestions((prevState) => prevState.map(q => q.id === data.id ? data : q));
    }

    const addNewQuestion = (data: Question) => {
        setAppendQuestion(false);
        setQuestions((prevState) => [data, ...prevState]);
    }

    const renderNewQuestionForm = () => {
        if (audit) {
            return <QuestionCarouselItem>
                {appendQuestion && <AuditQuestionEditor
                    className={styles.auditQuestion}
                    onDelete={() => setAppendQuestion(false)}
                    onCreate={(q: Question) => addNewQuestion(q)}
                />
                }
            </QuestionCarouselItem>
        }
    }

    const appendNewQuestionForm = () => {
        if (ref.current) {
            ref.current.slideTo(0);
            setAppendQuestion(prev => !prev);
        }
    }

    return <Card className={classNames(styles.card, styles.list)}>
        <CardContent className={styles.cardContainer}>
            <div className={styles.cardRow}>
                <div className={styles.cardColumn}>
                    <div className={styles.inputGroup}>
                        <TextField label="Name"
                                   value={auditName}
                                   InputLabelProps={{shrink: auditName?.trim().length > 0}}
                                   onChange={e => setAuditName(e.target.value)}
                                   required
                                   fullWidth
                                   error={auditName?.trim().length === 0}
                        />
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-ID">ID</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-ID"
                                value={auditId}
                                label="ID"
                                readOnly
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={copyValue} edge="end">
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <TextField label={"Beschreibung"}
                               value={auditDescription}
                               InputLabelProps={{shrink: auditDescription?.trim().length > 0}}
                               onChange={e => setAuditDescription(e.target.value)}
                               fullWidth
                               multiline
                               rows={4}
                               required
                               error={auditDescription?.trim().length === 0}
                    />
                </div>
                <div className={styles.cardColumn}>
                    <div className={styles.cardRow}>
                        <div className={styles.inputGroup}>
                            <FormControl fullWidth>
                                <InputLabel id="phase-checkbox-label">Phase</InputLabel>
                                <Select labelId="phase-checkbox-label"
                                        label={"Phase"}
                                        value={auditPhase}
                                        onChange={(e) => setAuditPhase(e.target.value as unknown as AuditPhase)}
                                >
                                    <MenuItem value={AuditPhase.basic}>{AuditPhase.basic}</MenuItem>
                                    <MenuItem value={AuditPhase.thematic}>{AuditPhase.thematic}</MenuItem>
                                    <MenuItem value={AuditPhase.specific}>{AuditPhase.specific}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="active-checkbox-label">Aktiv</InputLabel>
                                <Select labelId="active-checkbox-label"
                                        label={"Aktiv"}
                                        value={auditIsActive.toString() === "true" }
                                        onChange={(e) => setAuditIsActive(e.target.value === "true")}
                                >
                                    <MenuItem value={"true"}>ja</MenuItem>
                                    <MenuItem value={"false"}>nein</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="template-checkbox-label">Template</InputLabel>
                                <Select labelId="template-checkbox-label"
                                        label={"Template"}
                                        value={auditIsTemplate.toString() === "true"}
                                        onChange={(e) => setAuditIsTemplate(e.target.value === "true")}
                                >
                                    <MenuItem value={"true"}>ja</MenuItem>
                                    <MenuItem value={"false"}>nein</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.cardRow}>
                {renderActions()}
            </div>
            {audit &&
                <AuditEditorContext.Provider value={{audit}}>
                    <Accordion className={styles.questionAccordion}>
                        <AccordionSummary className={styles.accordionSummary} expandIcon={<ExpandMore/>}>
                            <Typography>Fragen Editor</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDetails}>
                            {loading && <div className={styles.progressContainer}>
                                <CircularProgress className={styles.circular} size={100}/>
                            </div>
                            }
                            {!loading && <>
                                <CarouselFilter items={questions}
                                                onFilterChange={updateFilteredQuestions}
                                                action={
                                                    <Tooltip title="Neue Frage hinzufügen">
                                                        <IconButton size="medium" onClick={() => appendNewQuestionForm()} color={"primary"} sx={{m: 1}}>
                                                            <AddIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                />
                                <QuestionCarousel ref={ref} itemsPerSlide={3}>
                                    {renderNewQuestionForm()}
                                    {filteredQuestions?.map(q =>
                                        <QuestionCarouselItem key={q.id}>
                                            <AuditQuestionEditor questionData={q}
                                                                 onChange={(data: Question) => updateQuestionData(data)}
                                            />
                                        </QuestionCarouselItem>
                                    )}
                                </QuestionCarousel>
                            </>
                            }
                        </AccordionDetails>
                    </Accordion>
                </AuditEditorContext.Provider>
            }
        </CardContent>
        {loading && <Backdrop open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        }
    </Card>
}

export default AuditEditor