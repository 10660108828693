import {Button, Container, Grow, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import styles from "../home-page.module.scss";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import useOnScreen from "../../../hooks/useOnScreen";


export const WelcomeSection = () => {
    const {t} = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, true);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xl'));

    return <Grow in={isVisible} timeout={1000}>
        <Paper ref={ref} className={styles.contactSection} variant="outlined">
            <div className={styles.contactPane}>
                {matches && <Container sx={{padding: "5rem"}}/>}
                <Typography className={styles.contactTitle} variant="h3">
                    {t("content.homepage.welcomeSection.title")}
                </Typography>
                <Typography className={styles.contactMessage} variant="h6">
                    {t("content.homepage.welcomeSection.subtitle")}
                </Typography>
                <NavLink to={t('content.homepage.welcomeSection.action.href')}>
                    <Button className={styles.contactButton} variant={"contained"} sx={{color: 'white'}}>
                        {t('content.homepage.welcomeSection.action.title')}
                    </Button>
                </NavLink>
                {matches && <Container sx={{padding: "7rem"}}/>}
            </div>
        </Paper>
    </Grow>

}
