import React from "react";
import {CasePage} from "./pages/case/CasePage";
import {HomePage} from "./pages/home/HomePage";
import {NewsPage} from "./pages/news/NewsPage";
import {ArticleView} from "./components/news/article/ArticleView";
import AuditCatalogPage from "./pages/audit-catalog/AuditCatalogPage";
import {CentralRegisterPage} from "./pages/register/CentralRegisterPage";
import {ForbiddenPage} from "./pages/forbidden/ForbiddenPage";
import {CreditsPage} from "./pages/attribution-credits/CreditsPage";
import {NotFoundPage} from "./pages/notfound/NotFoundPage";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HomeIcon from '@mui/icons-material/Home';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ArticleIcon from '@mui/icons-material/Article';
import {UserGroups} from "./helpers/UserGroups";

export const NavigationRoutes = [
    {
        i8nLabel: "navigation.dashboardPage",
        element: <HomePage/>,
        path: "/",
        menuIcon: <HomeIcon/>
    },
    {
        i8nLabel: "navigation.casePage",
        element: <CasePage/>,
        path: "/cases",
        menuIcon: <LibraryBooksIcon/>,
        groups: [UserGroups.ADMIN],
    },
    {
        i8nLabel: "navigation.newsPage",
        element: <NewsPage/>,
        path: "/news",
        menuIcon: <ArticleIcon/>
    },
    {
        element: <ArticleView/>,
        path: "/news/:id",
    },
    {
        i8nLabel: "navigation.auditEditorPage",
        element: <AuditCatalogPage/>,
        path: "/audit-catalog",
        menuIcon: <EditCalendarIcon/>,
        groups: [UserGroups.ADMIN],
    },
    {
        i8nLabel: "navigation.registerPage",
        element: <CentralRegisterPage/>,
        path: "/central-register",
        menuIcon: <AutoAwesomeMotionIcon/>,
        groups: [UserGroups.ADMIN],
    },
    {
        element: <ForbiddenPage/>,
        path: "/forbidden",
    },
    {
        element: <CreditsPage/>,
        path: "/attribution-credits",
    },
    {
        element: <NotFoundPage/>,
        path: "*",
    }
];