import {Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, Grow, Typography} from "@mui/material";
import React, {ReactNode, useRef} from "react";

import styles from "./news-widget-card.module.scss";
import useOnScreen from "../../hooks/useOnScreen";
import {Link} from "react-router-dom";
import {Path} from "@remix-run/router/history";
import parse from "html-react-parser";
import {getDayMonthDate} from "../../helpers/CommonUtils";
import {Article} from "../../API";
import {NewsEditor} from "./editor/NewsEditor";

type NewsWidgetCardProps = {
    article: Article,
    action?: ReactNode,
    to?: string | Partial<Path>,
    shorten?: boolean
}

export const NewsCard = (props: NewsWidgetCardProps) => {
    const {article, shorten, action, to} = props;
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, true);

    const renderIntroduction = () => {
        if (shorten) {
            return parse(article.introduction?.substring(0, 100) + "...");
        }
        return parse(article.introduction);
    }

    return <Grow in={isVisible} timeout={1000}>
        <Card ref={ref} raised>
            <div className={styles.articleAction}>
                <Link to={to as string} style={{textDecoration: 'none', color: "inherit"}}>
                    <CardHeader className={styles.articleHeader}
                                title={
                                    <Typography variant="caption" className={styles.articleTitle}>
                                        {article.title}
                                    </Typography>
                                }
                                subheader={
                                    <Typography variant="subtitle1" className={styles.articleDate}>
                                        {getDayMonthDate(article.createdAt)}
                                    </Typography>
                                }
                                action={
                                    action
                                }/>
                    {article.thumbnail && <CardMedia component="img" height="auto" image={article.thumbnail}/>}
                    <CardContent sx={{paddingTop: "0", paddingBottom: "0.5rem"}}>
                        <Typography variant="body2" color="text.secondary">
                            {renderIntroduction()}
                        </Typography>
                    </CardContent>
                </Link>
                <CardActions>
                    {article.topics && article.topics.map(t => <Link key={article.id + t} to={to as string} style={{textDecoration: 'none', color: "inherit"}}>
                            <Chip label={t}/>
                        </Link>
                    )}
                    <NewsEditor sx={{marginLeft: "auto"}} article={article}/>
                </CardActions>
                <span className={styles.focusHighlight}></span>
            </div>
        </Card>
    </Grow>
}

