import {BrowserRouter, Route, Routes} from "react-router-dom";
import {NavigationRoutes} from "./NavigationRoutes";
import React, {ReactNode} from "react";
import {useUserData} from "./context/UserContext";

type AppRouterProps = {
    layout: ReactNode;
}

export const AppRouter = ({layout}: AppRouterProps) => {
    const userData = useUserData();
    return <BrowserRouter>
        <Routes>
            <Route element={layout}>
                {NavigationRoutes
                    .filter(a => {
                        if (a.groups && userData) {
                            return a.groups.some(r => userData["custom:azure_groups"].includes(r));
                        }
                        return true;
                    })
                    .map(r => <Route key={r.path} path={r.path} element={r.element}/>)
                }
            </Route>
        </Routes>
    </BrowserRouter>
}