import React, {useState} from "react";
import {ReactComponent as Logo} from "./vhv-gruppe-logo.svg";
import {NavLink} from "react-router-dom";
import {Auth} from "aws-amplify";
import styles from "./toolbar.module.scss"
import {useUserData} from "../../context/UserContext";
import i18next from "i18next";
import {IconButton, MenuItem, Select, Typography} from "@mui/material";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export const AppToolbar = () => {
    const userData = useUserData();
    const [language, setLanguage] = useState(i18next.languages[0]);

    const changeLanguage = (language: string) => {
        i18next.changeLanguage(language)
            .then(() => setLanguage(language));
    }

    const renderLanguageSelection = () => {
        return Object.keys(i18next.services.resourceStore.data).map((l: string) => <MenuItem key={l} value={l}>{l}</MenuItem>)
    }

    const renderSignOutButton = () => {
        if (userData) {
            return <IconButton onClick={() => Auth.signOut()} color={"secondary"}>
                <ExitToAppIcon/>
            </IconButton>
        }
        return null;
    }

    return <header className={styles.header}>
        <NavLink to={"/"} aria-label="logo" className={styles.vhvGroupLogo}>
            <Logo/>
        </NavLink>
        <div className={styles.headerGap}></div>
        <Typography className={styles.loggedInUsername} aria-label="username">
            {userData && userData["email"]}
        </Typography>
        {renderSignOutButton()}
        <Select className={styles.languageSelection} variant="standard" value={language} onChange={e => changeLanguage(e.target.value)}>
            {renderLanguageSelection()}
        </Select>
    </header>
};
