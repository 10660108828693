/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://rr3njbt2pvdx3jv5x6g3h5mh5q.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:61897a28-f945-4a5a-8a3d-c24bc747b9ac",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_YKcE3Ruk3",
    "aws_user_pools_web_client_id": "7f614l7osflnqk2rag82gq7c5f",
    "oauth": {
        "domain": "acap-main.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "openid"
        ],
        "redirectSignIn": "https://main.d2gucsij3dy82d.amplifyapp.com/,https://www.vhv-cloud-onboarding.center/",
        "redirectSignOut": "https://main.d2gucsij3dy82d.amplifyapp.com/,https://www.vhv-cloud-onboarding.center/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ]
};


export default awsmobile;
