import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {Auth} from "aws-amplify";

type AuthContextType = {
    user: CognitoUser;
    session: CognitoUserSession;
}

const UserContext = createContext<AuthContextType | null>(null);

const UserContextProvider = (props: any) => {
    const children: ReactNode = props.children;
    const [user, setUser] = useState<CognitoUser | null>(null);
    const [session, setSession] = useState<CognitoUserSession | null>(null);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                setUser(currentUser);
                setSession(currentUser.getSignInUserSession());
            })
            .catch(() => Auth.federatedSignIn({customProvider: "Azure-AD"}));
    }, []);

    return (
        <UserContext.Provider value={{user: user, session: session} as AuthContextType}>
            {children}
        </UserContext.Provider>
    );
};

const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error("useUserContext was used outside of its Provider");
    }
    return context;
}

const useUserData = () => {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error("useUserContext was used outside of its Provider");
    }
    return context.user?.getSignInUserSession()?.getIdToken().payload;
}

const useUserToken = () => {
    const getJwtToken = useCallback(async (): Promise<string> => {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    }, []);
    return {getJwtToken};
}

export {UserContext, useUserData, UserContextProvider, useUserContext, useUserToken};