import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Notification} from "../helpers/notification/Notification";
import {useAuditService} from "../hooks/services/AuditService";

export type AutocompletionEntry = {
    phase: string;
    name: string;
    id: string;
}

type AuditAutocompletionContextType = {
    auditAutocompletionData: Array<AutocompletionEntry>;
}

const AuditAutocompletionContext = createContext<AuditAutocompletionContextType | null>(null);

const AuditAutocompletionContextProvider = (props: any) => {
    const children: ReactNode = props.children;
    const {listTemplateAudits} = useAuditService();
    const [auditAutocompletionData, setAuditAutocompletionData] = useState<Array<AutocompletionEntry>>([]);

    useEffect(() => {
        listTemplateAudits(null)
            .then(a => setAuditAutocompletionData(a))
            .catch(() => Notification.error("Could not retrieve audits information."))
    }, [listTemplateAudits])
    return <AuditAutocompletionContext.Provider value={{auditAutocompletionData: auditAutocompletionData}}>
        {children}
    </AuditAutocompletionContext.Provider>
};

const useAuditAutocompletionData = (): Array<AutocompletionEntry> => {
    const context = useContext(AuditAutocompletionContext);
    if (context === null) {
        throw new Error("AuditEditorContext was used outside of its Provider");
    }
    return context.auditAutocompletionData;
}

export {AuditAutocompletionContext, AuditAutocompletionContextProvider, useAuditAutocompletionData};