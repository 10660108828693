import styles from "../../home-page.module.scss";
import {Grid, Grow} from "@mui/material";
import React, {ReactNode, useRef} from "react";
import useOnScreen from "../../../../hooks/useOnScreen";
import {TranslatedTypography} from "../../../../components/common/TranslatedTypography";


type TeamProps = {
    title: string,
    children: ReactNode[],
    className?: string,
    orientation?: "left" | "right",
}

export const Team = ({title, children, className, orientation = "left"}: TeamProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, true);

    return <Grow in={isVisible} timeout={1000}>
        <Grid ref={ref} container className={className}>
            {orientation === "left" &&
                <Grid item xs={4}>
                    <div className={styles.teamTitleSection}>
                        <TranslatedTypography className={styles.teamTitle} variant="h4" count={1}>
                            {title}
                        </TranslatedTypography>
                    </div>
                </Grid>
            }
            <Grid item xs={8}>
                <Grid container spacing={2}>
                    {children && children.map((c: ReactNode, i) => <Grid key={title + i} item xs={6}>{c}</Grid>)}
                </Grid>
            </Grid>
            {orientation === "right" &&
                <Grid item xs={4}>
                    <div className={styles.teamTitleSection}>
                        <TranslatedTypography className={styles.teamTitle} variant="h4" count={1}>
                            {title}
                        </TranslatedTypography>
                    </div>
                </Grid>
            }
        </Grid>
    </Grow>
}
