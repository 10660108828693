import React, {useEffect, useState} from "react";
import {Case} from "../../API";
import {Notification} from "../../helpers/notification/Notification";
import styles from "styles/styles.module.scss";
import {useCaseService} from "../../hooks/services/CaseService";
import {Card, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";

export const CentralRegisterPage = () => {
    const {t} = useTranslation();
    const {listCases} = useCaseService();
    const [cases, setCases] = useState<Array<Case>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        listCases()
            .then(c => setCases(c))
            .catch((e) => Notification.error("Could not retrieve cases information.", e))
            .finally(() => setLoading(false))

    }, [listCases])

    const renderRegisterData = () => {
        return cases?.map((c) =>
            <TableRow key={c.id}>
                <TableCell>{c.id}</TableCell>
                <TableCell>{c.name}</TableCell>
                <TableCell>{c.description}</TableCell>
                <TableCell>{c.projectOwner}</TableCell>
                <TableCell>{c.projectResponsible}</TableCell>
                <TableCell>{c.riscOfficer}</TableCell>
                <TableCell>In Arbeit</TableCell>
            </TableRow>
        )
    }

    const renderRegister = () => {
        return <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("content.register.columns.id")}</TableCell>
                        <TableCell>{t("content.register.columns.name")}</TableCell>
                        <TableCell>{t("content.register.columns.description")}</TableCell>
                        <TableCell>{t("content.register.columns.projectOwner")}</TableCell>
                        <TableCell>{t("content.register.columns.riscOfficer")}</TableCell>
                        <TableCell>{t("content.register.columns.projectResponsible")}</TableCell>
                        <TableCell>{t("content.register.columns.status")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderRegisterData()}
                </TableBody>
            </Table>
        </TableContainer>
    }

    return <Card className={styles.card}>
        <CardContent>
            {renderRegister()}
        </CardContent>
    </Card>
}
