import React, {useEffect, useState} from "react";

import {AuditPhase, Case, CaseStatus, CreateCaseInput, UpdateCaseInput} from "../../../../API";
import {Notification} from "../../../../helpers/notification/Notification";
import {isNotBlank} from "../../../../helpers/CommonUtils";
import styles from "./case-card.module.scss";
import {Card, CardContent, CardHeader, Chip, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Tooltip} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useCaseService} from "../../../../hooks/services/CaseService";
import {AuditPhaseStepper} from "../../../../components/audit/audit-stepper/AuditPhaseStepper";
import {useCaseData} from "../../../../context/CaseContext";
import {useUserContext} from "../../../../context/UserContext";
import {useTranslation} from "react-i18next";
import SaveIcon from '@mui/icons-material/Save';
import dayjs from "dayjs";

type CaseCardProps = {
    onCreate?: Function,
}


function CaseCard({onCreate}: CaseCardProps) {
    const {currentCase, setCase} = useCaseData();
    const {t} = useTranslation();
    const userContext = useUserContext();
    const {updateCase, createCaseWithTemplateAudits} = useCaseService();
    const [caseId, setCaseId] = useState<string>();
    const [version, setVersion] = useState(1);
    const [caseName, setCaseName] = useState<string>("");
    const [caseDescription, setCaseDescription] = useState<string>("");
    const [casePhase, setCasePhase] = useState<AuditPhase>(AuditPhase.basic);
    const [caseStatus, setCaseStatus] = useState<CaseStatus>(CaseStatus.open);
    const [riscOfficer, setRiscOfficer] = useState<string>("");
    const [projectResponsible, setProjectResponsible] = useState<string>("");
    const [projectOwner, setProjectOwner] = useState<string>("");
    const [costUnit, setCostUnit] = useState<string>("");
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentCase) {
            setCaseId(currentCase.id);
            setVersion(currentCase._version);
            setCaseName(currentCase.name);
            setCaseDescription(currentCase.description);
            setRiscOfficer(currentCase.riscOfficer!);
            setProjectResponsible(currentCase.projectResponsible!);
            setProjectOwner(currentCase.projectOwner!);
            setCostUnit(currentCase.costUnit!);
            setCasePhase(currentCase.phase!);
            setCaseStatus(currentCase.status!);
            setDisabled(currentCase.owner !== userContext.user.getUsername());
        }
    }, [currentCase, userContext.user])

    const getCaseData = () => {
        if (validateInput())
            return {
                id: caseId,
                name: caseName,
                description: caseDescription,
                riscOfficer: riscOfficer,
                projectResponsible: projectResponsible,
                projectOwner: projectOwner,
                phase: casePhase,
                status: caseStatus,
                costUnit: costUnit,
                _version: version
            }
        Notification.error("Please insert at least a name and a description.")
        return null;
    }

    const validateInput = () => {
        return isNotBlank(caseName) && isNotBlank(caseDescription);
    }

    const createNewCase = () => {
        setLoading(true);
        createCaseWithTemplateAudits(getCaseData() as CreateCaseInput)
            .then((c: Case) => {
                setCase(c);
                onCreate && onCreate(c);
            })
            .then(() => Notification.success("Case created."))
            .catch((e) => Notification.error("Case could not be created", e))
            .finally(() => setLoading(false));
    }

    const updateCurrentCase = () => {
        updateCase(getCaseData() as UpdateCaseInput)
            .then((c: Case) => setCase(c))
            .then(() => Notification.success("Case updated."))
            .catch(() => Notification.error("Case could not be created"));
    }

    const copyCaseId = () => {
        if (caseId) {
            navigator.clipboard.writeText(caseId).then(r => Notification.success("Case id copied to clipboard."));
        }
    }

    const renderCaseActions = () => {
        if (caseId && !disabled && caseStatus !== CaseStatus.inReview) {
            return <Tooltip title={t("action.update")}>
                <IconButton onClick={updateCurrentCase} color={"primary"}>
                    <SaveIcon/>
                </IconButton>
            </Tooltip>
        }
        if (!caseId) {
            return <Tooltip title={t("action.create")}>
                <IconButton onClick={createNewCase} color={"primary"}>
                    <SaveIcon/>
                </IconButton>
            </Tooltip>
        }
    }

    const getStatusColor = () => {
        switch (caseStatus) {
            case CaseStatus.open:
                return "default";
            case CaseStatus.inProgress:
                return "secondary";
            case CaseStatus.inReview:
                return "warning";
            case CaseStatus.cancelled:
                return "error";
            default:
                return "success";
        }
    }

    const lasUpdatedAt = () => {
        if (currentCase) {
            const i18nPrefix = t('content.common.updatedAt');
            const updatedAt = i18nPrefix + dayjs(currentCase.updatedAt).fromNow();
            return <Tooltip title={dayjs(currentCase.updatedAt).format().toString()}>
                <Chip label={updatedAt}/>
            </Tooltip>
        }
    }
    const createdFrom = () => {
        if (currentCase) {
            const i18nPrefix = t('content.common.createdFrom');
            return <Chip label={i18nPrefix + currentCase.owner}/>
        }
    }

    const renderStatus = () => {
        return <div className={styles.caseStatusContainer}>
            <Chip className={styles.caseStatus} color={getStatusColor()} label={t(`content.status.${caseStatus}`)}/>
            {createdFrom()}
            {lasUpdatedAt()}
        </div>
    }

    const renderCaseCard = () => <Card className={styles.card}>
        <CardHeader title={renderStatus()} action={renderCaseActions()}></CardHeader>
        <CardContent className={styles.cardContainer}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField label={"Name"}
                                       size="small"
                                       value={caseName}
                                       onChange={e => setCaseName(e.target.value)}
                                       required
                                       InputProps={{
                                           readOnly: disabled,
                                       }}
                                       fullWidth
                                       error={caseName?.trim().length === 0}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-ID">ID</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-ID"
                                    value={caseId || "---"}
                                    label="ID"
                                    readOnly
                                    size="small"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={copyCaseId} edge="end">
                                                <ContentCopyIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={"Beschreibung"}
                                       size="small"
                                       value={caseDescription}
                                       onChange={e => setCaseDescription(e.target.value)}
                                       required
                                       InputProps={{
                                           readOnly: disabled,
                                       }}
                                       fullWidth
                                       multiline
                                       rows={6}
                                       error={caseDescription?.trim().length === 0}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container columns={1} spacing={2}>
                        <Grid item xs={1}>
                            <TextField label={"Auftraggeber"}
                                       value={projectOwner}
                                       onChange={e => setProjectOwner(e.target.value)}
                                       InputProps={{
                                           readOnly: disabled,
                                       }}
                                       fullWidth
                                       size="small"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField label={"Maßnahmenverantwortlicher"}
                                       value={projectResponsible}
                                       onChange={e => setProjectResponsible(e.target.value)}
                                       InputProps={{
                                           readOnly: disabled,
                                       }}
                                       fullWidth
                                       size="small"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField label={"Risikoverantwortlicher"}
                                       value={riscOfficer}
                                       onChange={e => setRiscOfficer(e.target.value)}
                                       InputProps={{
                                           readOnly: disabled,
                                       }}
                                       fullWidth
                                       size="small"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField label={"Kostenstelle"}
                                       value={costUnit}
                                       onChange={e => setCostUnit(e.target.value)}
                                       InputProps={{
                                           readOnly: disabled,
                                       }}
                                       fullWidth
                                       size="small"
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <div className={styles.cardRow}>
            </div>
            <div className={styles.cardRow}>
                <AuditPhaseStepper/>
            </div>
        </CardContent>
        {
            loading && <CircularProgress/>
        }
    </Card>

    return renderCaseCard();
}

export default CaseCard