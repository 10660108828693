/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      id
      name
      description
      phase
      status
      costUnit
      riscOfficer
      projectResponsible
      projectOwner
      owner
      editors
      audits {
        items {
          id
          caseID
          name
          description
          phase
          originAuditID
          isActive
          isTemplate
          case {
            id
            name
            description
            phase
            status
            costUnit
            riscOfficer
            projectResponsible
            projectOwner
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          questions {
            nextToken
            startedAt
            __typename
          }
          owner
          editors
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
      name
      description
      phase
      status
      costUnit
      riscOfficer
      projectResponsible
      projectOwner
      owner
      editors
      audits {
        items {
          id
          caseID
          name
          description
          phase
          originAuditID
          isActive
          isTemplate
          case {
            id
            name
            description
            phase
            status
            costUnit
            riscOfficer
            projectResponsible
            projectOwner
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          questions {
            nextToken
            startedAt
            __typename
          }
          owner
          editors
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase(
    $input: DeleteCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    deleteCase(input: $input, condition: $condition) {
      id
      name
      description
      phase
      status
      costUnit
      riscOfficer
      projectResponsible
      projectOwner
      owner
      editors
      audits {
        items {
          id
          caseID
          name
          description
          phase
          originAuditID
          isActive
          isTemplate
          case {
            id
            name
            description
            phase
            status
            costUnit
            riscOfficer
            projectResponsible
            projectOwner
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          questions {
            nextToken
            startedAt
            __typename
          }
          owner
          editors
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAudit = /* GraphQL */ `
  mutation CreateAudit(
    $input: CreateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    createAudit(input: $input, condition: $condition) {
      id
      caseID
      name
      description
      phase
      originAuditID
      isActive
      isTemplate
      case {
        id
        name
        description
        phase
        status
        costUnit
        riscOfficer
        projectResponsible
        projectOwner
        owner
        editors
        audits {
          items {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      questions {
        items {
          id
          auditID
          question
          answer {
            type
            __typename
          }
          favourite
          description
          audit {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAudit = /* GraphQL */ `
  mutation UpdateAudit(
    $input: UpdateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    updateAudit(input: $input, condition: $condition) {
      id
      caseID
      name
      description
      phase
      originAuditID
      isActive
      isTemplate
      case {
        id
        name
        description
        phase
        status
        costUnit
        riscOfficer
        projectResponsible
        projectOwner
        owner
        editors
        audits {
          items {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      questions {
        items {
          id
          auditID
          question
          answer {
            type
            __typename
          }
          favourite
          description
          audit {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAudit = /* GraphQL */ `
  mutation DeleteAudit(
    $input: DeleteAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    deleteAudit(input: $input, condition: $condition) {
      id
      caseID
      name
      description
      phase
      originAuditID
      isActive
      isTemplate
      case {
        id
        name
        description
        phase
        status
        costUnit
        riscOfficer
        projectResponsible
        projectOwner
        owner
        editors
        audits {
          items {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      questions {
        items {
          id
          auditID
          question
          answer {
            type
            __typename
          }
          favourite
          description
          audit {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      auditID
      question
      answer {
        type
        selections {
          selected
          value
          auditReferences
          __typename
        }
        __typename
      }
      favourite
      description
      audit {
        id
        caseID
        name
        description
        phase
        originAuditID
        isActive
        isTemplate
        case {
          id
          name
          description
          phase
          status
          costUnit
          riscOfficer
          projectResponsible
          projectOwner
          owner
          editors
          audits {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        questions {
          items {
            id
            auditID
            question
            favourite
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            editors
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        owner
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      editors
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      auditID
      question
      answer {
        type
        selections {
          selected
          value
          auditReferences
          __typename
        }
        __typename
      }
      favourite
      description
      audit {
        id
        caseID
        name
        description
        phase
        originAuditID
        isActive
        isTemplate
        case {
          id
          name
          description
          phase
          status
          costUnit
          riscOfficer
          projectResponsible
          projectOwner
          owner
          editors
          audits {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        questions {
          items {
            id
            auditID
            question
            favourite
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            editors
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        owner
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      editors
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      auditID
      question
      answer {
        type
        selections {
          selected
          value
          auditReferences
          __typename
        }
        __typename
      }
      favourite
      description
      audit {
        id
        caseID
        name
        description
        phase
        originAuditID
        isActive
        isTemplate
        case {
          id
          name
          description
          phase
          status
          costUnit
          riscOfficer
          projectResponsible
          projectOwner
          owner
          editors
          audits {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        questions {
          items {
            id
            auditID
            question
            favourite
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            editors
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        owner
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      editors
      __typename
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      title
      introduction
      content
      thumbnail
      active
      pinned
      topics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      title
      introduction
      content
      thumbnail
      active
      pinned
      topics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      title
      introduction
      content
      thumbnail
      active
      pinned
      topics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
