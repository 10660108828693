/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchArticles = /* GraphQL */ `
  query SearchArticles(
    $filter: SearchableArticleFilterInput
    $sort: [SearchableArticleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableArticleAggregationInput]
  ) {
    searchArticles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        introduction
        content
        thumbnail
        active
        pinned
        topics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      name
      description
      phase
      status
      costUnit
      riscOfficer
      projectResponsible
      projectOwner
      owner
      editors
      audits {
        items {
          id
          caseID
          name
          description
          phase
          originAuditID
          isActive
          isTemplate
          case {
            id
            name
            description
            phase
            status
            costUnit
            riscOfficer
            projectResponsible
            projectOwner
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          questions {
            nextToken
            startedAt
            __typename
          }
          owner
          editors
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        phase
        status
        costUnit
        riscOfficer
        projectResponsible
        projectOwner
        owner
        editors
        audits {
          items {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCases = /* GraphQL */ `
  query SyncCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        phase
        status
        costUnit
        riscOfficer
        projectResponsible
        projectOwner
        owner
        editors
        audits {
          items {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAudit = /* GraphQL */ `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      id
      caseID
      name
      description
      phase
      originAuditID
      isActive
      isTemplate
      case {
        id
        name
        description
        phase
        status
        costUnit
        riscOfficer
        projectResponsible
        projectOwner
        owner
        editors
        audits {
          items {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      questions {
        items {
          id
          auditID
          question
          answer {
            type
            __typename
          }
          favourite
          description
          audit {
            id
            caseID
            name
            description
            phase
            originAuditID
            isActive
            isTemplate
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseID
        name
        description
        phase
        originAuditID
        isActive
        isTemplate
        case {
          id
          name
          description
          phase
          status
          costUnit
          riscOfficer
          projectResponsible
          projectOwner
          owner
          editors
          audits {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        questions {
          items {
            id
            auditID
            question
            favourite
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            editors
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        owner
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAudits = /* GraphQL */ `
  query SyncAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAudits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        caseID
        name
        description
        phase
        originAuditID
        isActive
        isTemplate
        case {
          id
          name
          description
          phase
          status
          costUnit
          riscOfficer
          projectResponsible
          projectOwner
          owner
          editors
          audits {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        questions {
          items {
            id
            auditID
            question
            favourite
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            editors
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        owner
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      auditID
      question
      answer {
        type
        selections {
          selected
          value
          auditReferences
          __typename
        }
        __typename
      }
      favourite
      description
      audit {
        id
        caseID
        name
        description
        phase
        originAuditID
        isActive
        isTemplate
        case {
          id
          name
          description
          phase
          status
          costUnit
          riscOfficer
          projectResponsible
          projectOwner
          owner
          editors
          audits {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        questions {
          items {
            id
            auditID
            question
            favourite
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            editors
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        owner
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      editors
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        auditID
        question
        answer {
          type
          selections {
            selected
            value
            auditReferences
            __typename
          }
          __typename
        }
        favourite
        description
        audit {
          id
          caseID
          name
          description
          phase
          originAuditID
          isActive
          isTemplate
          case {
            id
            name
            description
            phase
            status
            costUnit
            riscOfficer
            projectResponsible
            projectOwner
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          questions {
            nextToken
            startedAt
            __typename
          }
          owner
          editors
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        editors
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuestions = /* GraphQL */ `
  query SyncQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        auditID
        question
        answer {
          type
          selections {
            selected
            value
            auditReferences
            __typename
          }
          __typename
        }
        favourite
        description
        audit {
          id
          caseID
          name
          description
          phase
          originAuditID
          isActive
          isTemplate
          case {
            id
            name
            description
            phase
            status
            costUnit
            riscOfficer
            projectResponsible
            projectOwner
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          questions {
            nextToken
            startedAt
            __typename
          }
          owner
          editors
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        editors
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      title
      introduction
      content
      thumbnail
      active
      pinned
      topics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        introduction
        content
        thumbnail
        active
        pinned
        topics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticles = /* GraphQL */ `
  query SyncArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        introduction
        content
        thumbnail
        active
        pinned
        topics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const auditsByCaseID = /* GraphQL */ `
  query AuditsByCaseID(
    $caseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditsByCaseID(
      caseID: $caseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseID
        name
        description
        phase
        originAuditID
        isActive
        isTemplate
        case {
          id
          name
          description
          phase
          status
          costUnit
          riscOfficer
          projectResponsible
          projectOwner
          owner
          editors
          audits {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        questions {
          items {
            id
            auditID
            question
            favourite
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            editors
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        owner
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const questionsByAuditID = /* GraphQL */ `
  query QuestionsByAuditID(
    $auditID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByAuditID(
      auditID: $auditID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        auditID
        question
        answer {
          type
          selections {
            selected
            value
            auditReferences
            __typename
          }
          __typename
        }
        favourite
        description
        audit {
          id
          caseID
          name
          description
          phase
          originAuditID
          isActive
          isTemplate
          case {
            id
            name
            description
            phase
            status
            costUnit
            riscOfficer
            projectResponsible
            projectOwner
            owner
            editors
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          questions {
            nextToken
            startedAt
            __typename
          }
          owner
          editors
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        editors
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
