import {CardContent, CardHeader} from "@mui/material";
import parse from "html-react-parser";
import {Article} from "../../../API";
import {getDayMonthDate} from "../../../helpers/CommonUtils";

type ArticleContentProps = {
    className?: string,
    article: Article,
}

export const ArticleContent = (props: ArticleContentProps) => {
    const {className, article} = props;

    return <div className={className}>
        <CardHeader title={article.title || "Title"} subheader={getDayMonthDate(article.createdAt)}/>
        <CardContent className={"mce-content-body"}>
            <p>
                {article.introduction || "Introduction"}
            </p>
            <br/>
            {article?.content && parse(article.content)}
        </CardContent>
    </div>
}