/* tslint:disable */
/* eslint-disable */

export const getCustomAudit = /* GraphQL */ `
    query GetCustomAudit($id: ID!) {
        getAudit(id: $id) {            
            name
            description
            phase
            questions {
                items {
                    question
                    description
                    answer {
                        type
                        selections {
                            value
                            selected
                            auditReferences
                        }
                    }
                    _deleted
                }
            }
        }
    }
`;

export const listAuditsWithBasicData = /* GraphQL */ `
    query listAuditsWithBasicData(
        $filter: ModelAuditFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                description
                phase
            }
            nextToken
            startedAt
        }
    }
`;