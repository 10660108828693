import {Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, OutlinedTextFieldProps, Switch, TextField, Theme} from "@mui/material";
import styles from "../../../pages/news/news-page.module.scss";
import {Editor} from "@tinymce/tinymce-react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {TranslatedTypography} from "../../common/TranslatedTypography";
import {ArticleContent} from "../article/ArticleContent";
import {Article} from "../../../API";
import {useArticleService} from "../../../hooks/services/ArticleService";
import {Notification} from "../../../helpers/notification/Notification";
import {useUserData} from "../../../context/UserContext";
import {SxProps} from "@mui/system";
import {UserGroups} from "../../../helpers/UserGroups";

type NewsEditorProps = {
    article?: Article,
    sx?: SxProps<Theme>;
}

export const NewsEditor = ({article, sx}: NewsEditorProps) => {
    const {t} = useTranslation();
    const userData = useUserData();
    const {createArticle, updateArticle} = useArticleService();
    const [value, setValue] = useState(article?.content || "");
    const [open, setOpen] = useState<boolean>(false);

    const [version, setVersion] = useState<number>(article?._version || 1);
    const [title, setTitle] = useState<string>(article?.title || "");
    const [introduction, setIntroduction] = useState<string>(article?.introduction || "");
    const [text, setText] = useState(article?.content || "");
    const [pinned, setPinned] = useState<boolean>(article?.pinned || false);
    const [active, setActive] = useState<boolean>(article?.active || false);
    const [topics, setTopics] = React.useState<string[]>(article?.topics || []);

    const clearData = () => {
        setText("");
        setTitle("");
        setIntroduction("");
        setValue("")
        setTopics([]);
        setActive(false);
        setPinned(false);
    }

    const handleCreateArticle = () => {
        createArticle({
            active: active,
            content: text,
            pinned: pinned,
            introduction: introduction,
            title: title,
            topics: topics
        })
            .then(() => Notification.success("Article created."))
            .then(() => clearData())
            .catch((e) => Notification.error("Article could not be created.", e));
    }

    const handleUpdateArticle = () => {
        if (article) {
            updateArticle({
                id: article.id,
                _version: version,
                active: active,
                content: text,
                pinned: pinned,
                introduction: introduction,
                title: title,
                topics: topics
            })
                .then(a => setVersion(a._version))
                .then(() => Notification.success("Article updated."))
                .catch((e) => Notification.error("Article could not be updated.", e));
        }
    }

    const renderOpenEditorActions = () => {
        if (article && userData && userData["custom:azure_groups"].includes(UserGroups.ADMIN)) {
            return <IconButton sx={sx} onClick={() => setOpen(true)} color="primary">
                <EditIcon/>
            </IconButton>
        } else if (!article) {
            return <div className={styles.createNews}>
                <Fab color="primary" onClick={() => setOpen(true)}>
                    <AddIcon/>
                </Fab>
            </div>
        }
    }

    const renderEditActions = () => {
        if (article) {
            return <Button variant={"contained"} color={"primary"} onClick={() => handleUpdateArticle()}>{t("action.update")}</Button>
        }
        return <Button variant={"contained"} color={"primary"} onClick={() => handleCreateArticle()}>{t("action.create")}</Button>
    }

    return <>
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
            <DialogTitle>
                {t("content.news.create.title")}
            </DialogTitle>
            <IconButton
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "primary",
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent className={styles.newsEditorContainer}>
                <Grid container className={styles.newsMetadata} spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField className={styles.selectionText}
                                           label={t("content.news.create.input.title")}
                                           value={title}
                                           onChange={(e) => setTitle(e.target.value)}
                                           fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={active}
                                            onChange={() => setActive(!active)}/>
                                    }
                                    label={t("content.news.create.input.active")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={pinned}
                                            onChange={() => setPinned(!pinned)}/>
                                    }
                                    label={t("content.news.create.input.pinned")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField className={styles.selectionText}
                                           label={t("content.news.create.input.introduction")}
                                           multiline
                                           rows={3}
                                           value={introduction}
                                           onChange={(e) => setIntroduction(e.target.value)}
                                           fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={topics}
                                    multiple
                                    onChange={(e, value) => setTopics(() => value)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...(params as unknown as OutlinedTextFieldProps)}
                                            variant="outlined"
                                            label={t("content.news.create.input.topics")}
                                        />
                                    )}
                                    options={[]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <TranslatedTypography variant={"h4"}>content.news.create.preview</TranslatedTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.newsEditor}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Editor
                                        value={value}
                                        onInit={(evt, editor) => {
                                            setText(editor.getContent({format: 'html'}));
                                        }}
                                        onEditorChange={(newValue, editor) => {
                                            setValue(newValue);
                                            setText(editor.getContent({format: 'html'}));
                                        }}
                                        init={{
                                            menubar: false,
                                            content_css: "/assets/css/article-styles.css",
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                'anchor', 'searchreplace', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'table |' +
                                                'removeformat | help',
                                            min_height: 700,
                                            // formats: {
                                            //     h1: {block: 'h1', classes: 'article-h1'},
                                            //     h2: {block: 'h2', classes: 'article-h2'},
                                            //     h3: {block: 'h3', classes: 'article-h3'},
                                            //     h4: {block: 'h4', classes: 'article-h4'},
                                            //     h5: {block: 'h5', classes: 'article-h5'},
                                            //     h6: {block: 'h6', classes: 'article-h6'},
                                            //     p: {block: 'p', classes: 'article-p'}
                                            // },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ArticleContent article={{id: "", title: title, introduction: introduction, content: text} as Article}/>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} onClick={() => setOpen(false)}>{t("action.cancel")}</Button>
                {renderEditActions()}
            </DialogActions>
        </Dialog>
        {renderOpenEditorActions()}
    </>
}