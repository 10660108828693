import {ReactNode} from "react";
import styles from "./question-carousel.module.scss";

type QuestionCarouselItemType = {
    children: ReactNode
}

export const QuestionCarouselItem = ({children}: QuestionCarouselItemType) => {

    return <li className={styles.carouselItem}>
        {children}
    </li>
}