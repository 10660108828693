import {ThemeOptions} from "@mui/material/styles";
import {createTheme} from "@mui/material";


export const themeOptions: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: "rgb(182, 191, 0)",
            contrastText: "rgb(81, 98, 111)",
        },
        secondary: {
            main: "rgb(81, 98, 111)",
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "1rem",
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 180,
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgb(81, 98, 111)",
                    color: "rgb(182, 191, 0)",
                    "&:hover": {
                        backgroundColor: "rgba(81, 98, 111, 0.8)",
                        color: "rgba(182, 191, 0.8)",
                    },
                    "&.Mui-selected": {
                        backgroundColor: "rgb(182, 191, 0)",
                        color: "rgb(81, 98, 111)",
                        "&:hover": {
                            backgroundColor: "rgba(182, 191, 0, 0.8)",
                            color: "rgba(81, 98, 111, 0.8)",
                        }
                    },
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {},
                colorPrimary: {
                    backgroundColor: "rgb(182, 191, 0)",
                    color: "rgb(81, 98, 111)",
                    borderRadius: 4,
                    "&:hover": {
                        backgroundColor: "rgb(81, 98, 111)",
                        color: "rgb(182, 191, 0)",
                    }
                }
            }
        },
    }
};

export const theme = createTheme(themeOptions);
