import {useUserToken} from "../../context/UserContext";
import {API, graphqlOperation, GraphQLQuery} from "@aws-amplify/api";
import {
    Audit,
    AuditPhase,
    AuditsByCaseIDQuery,
    CreateAuditInput,
    CreateAuditMutation,
    GetAuditQuery,
    ListAuditsQuery,
    ListAuditsQueryVariables,
    UpdateAuditInput,
    UpdateAuditMutation
} from "../../API";
import {getCustomAudit, listAuditsWithBasicData} from "../../graphql/custom-queries";
import {createAudit as createAuditOperation, updateAudit as updateAuditOperation} from "../../graphql/mutations";
import {Notification} from "../../helpers/notification/Notification";
import {useCallback} from "react";
import {auditsByCaseID, getAudit as getAuditOperation, listAudits as listAuditsOperation} from "../../graphql/queries";
import {useQuestionService} from "./QuestionService";

export const useAuditService = () => {
    const {getJwtToken} = useUserToken();
    const {createQuestions} = useQuestionService();

    const createAudit = useCallback(async (input: CreateAuditInput): Promise<Audit> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<CreateAuditMutation>>(graphqlOperation(createAuditOperation, {input: input}, token))
        return data?.createAudit as Audit;
    }, [getJwtToken]);

    const listAudits = useCallback(async (input: ListAuditsQueryVariables = {}): Promise<Array<Audit>> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<ListAuditsQuery>>(graphqlOperation(listAuditsOperation, input, token))
        return data?.listAudits?.items as Array<Audit>;
    }, [getJwtToken]);

    const listAuditsBasic = useCallback(async (input: ListAuditsQueryVariables = {}): Promise<Array<Audit>> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<ListAuditsQuery>>(graphqlOperation(listAuditsWithBasicData, input, token))
        return data?.listAudits?.items as Array<Audit>;
    }, [getJwtToken]);

    const retrieveAudit = useCallback(async (id: string): Promise<Audit> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<GetAuditQuery>>(graphqlOperation(getCustomAudit, {id: id}, token))
        return data?.getAudit as Audit;
    }, [getJwtToken]);

    const getAudit = useCallback(async (auditId: string): Promise<Audit> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<GetAuditQuery>>(graphqlOperation(getAuditOperation, {id: auditId}, token));
        return data?.getAudit as Audit;
    }, [getJwtToken]);

    const getAuditsByCaseID = useCallback(async (caseId: string): Promise<Array<Audit>> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<AuditsByCaseIDQuery>>(graphqlOperation(auditsByCaseID, {caseID: caseId}, token))
        return data?.auditsByCaseID?.items as Array<Audit>;
    }, [getJwtToken]);

    const updateAudit = useCallback(async (input: UpdateAuditInput): Promise<Audit> => {
        const token = await getJwtToken();
        const {data} = await API.graphql<GraphQLQuery<UpdateAuditMutation>>(graphqlOperation(updateAuditOperation, {input: input}, token));
        return data?.updateAudit as Audit;
    }, [getJwtToken]);

    const appendAudit = useCallback(async (templateId: string, caseId: string): Promise<void> => {
        if (templateId) {
            let templateAudit = await retrieveAudit(templateId) as Audit;
            if (templateAudit) {
                let {questions, ...newAuditInput} = templateAudit;
                newAuditInput.isTemplate = false;
                newAuditInput.isActive = true;
                newAuditInput.originAuditID = templateId;
                newAuditInput.caseID = caseId;
                const audit = await createAudit(newAuditInput);
                if (questions && audit) {
                    await createQuestions(questions.items, audit.id);
                }
            }
        }
    }, [createAudit, createQuestions, retrieveAudit]);

    const listTemplateAudits = useCallback(async (phase: AuditPhase | null): Promise<Array<Audit>> => {
        let listTemplateAudits: ListAuditsQueryVariables = {
            filter: {
                isActive: {eq: true},
                isTemplate: {eq: true},
                and: [
                    {
                        or: [
                            {_deleted: {eq: false}},
                            {_deleted: {attributeExists: false}}
                        ]
                    }
                ]
            }
        }
        if (phase && listTemplateAudits.filter) {
            listTemplateAudits.filter.phase = {eq: phase};
        }
        return listAuditsBasic(listTemplateAudits);
    }, [listAuditsBasic]);

    /*** Clones the template audits with a given phase and adds them to a case and persists cloned data. Clones also template audit questions
     *
     * @param {string} caseId - ACAP CaseID, where the template audits will be applied inside a database.
     * @param {AuditPhase} phase - all audits with a given audit phase will be added to a defined case. Default AuditPhase.basic.
     * ***/
    const appendTemplateAudits = useCallback(async (caseId: string, phase: AuditPhase = AuditPhase.basic): Promise<void> => {
        try {
            const templates = await listTemplateAudits(phase);
            if (templates) {
                for (const template of templates) {
                    await appendAudit(template.id, caseId);
                }
            }
        } catch (e) {
            Notification.error("Could not create new case.")
        }
    }, [appendAudit, listTemplateAudits]);

    return {getAuditsByCaseID, retrieveAudit, appendAudit, updateAudit, appendTemplateAudits, listTemplateAudits, listAudits, getAudit, createAudit};
}