import React from "react";

import styles from "styles/styles.module.scss";
import {Card} from "@mui/material";

export const NotFoundPage = () => {
    return <Card className={styles.card}>
        <div className={styles.cardContainer}>
            <div className={styles.cardColumn} style={{width: "50%", margin: "0 auto"}}>
                <img src={"/assets/404/404-robot.jpg"} alt={"not found"}/>
            </div>
        </div>
    </Card>
}
