import {Divider, Grid} from "@mui/material";

import styles from "./home-page.module.scss";
import React from "react";
import classNames from "classnames";
import {InformationSection} from "./components/InformationSection";
import {WelcomeSection} from "./components/WelcomeSection";
import {TeamsSection} from "./components/teams/TeamsSection";
import {NewsWidget} from "../../components/news/NewsWidget";
import {useTranslation} from "react-i18next";

const GRID_SPACING = 3;

export const HomePage = () => {
    const {t} = useTranslation();

    return <div className={styles.contentContainer}>
        <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
                <WelcomeSection/>
            </Grid>
            <Grid item xs={12} className={styles.snapScroll}>
                <Divider className={styles.homeSectionDivider} textAlign={"center"}>{t("content.homepage.startSection.title")}</Divider>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={GRID_SPACING}>
                    <Grid item>
                        <InformationSection i18nContent={[
                            {i18nPrefix: "content.homepage.startSection.firstStepsToCloud", href: "mailto:Team-Cloud@vhv.de"},
                            {imgSrc: "/assets/img/home/start.png"},
                            {imgSrc: "/assets/img/home/first-steps.png"},
                            {i18nPrefix: "content.homepage.startSection.firstStepsInsideCloud", href: "https://confluence.ads.vhv.de/display/CCC/Erste+Schritte+in+AWS"}
                        ]}
                        />
                    </Grid>
                    <Grid item>
                        <InformationSection i18nContent={[
                            {i18nPrefix: "content.homepage.deepDiveSection.securityBluePrint", href: "https://confluence.ads.vhv.de/display/CCC/Security+Blueprint"},
                            {imgSrc: "/assets/img/home/security.png"},
                            {imgSrc: "/assets/img/home/developer.png"},
                            {i18nPrefix: "content.homepage.deepDiveSection.developerGuideline", href: "https://confluence.ads.vhv.de/display/CCC/Developer+Guideline"}
                        ]}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} className={styles.snapScroll}>
                <NewsWidget className={classNames(styles.sectionContainer, styles.newsSection)}/>
            </Grid>
            <Grid item xs={12} className={styles.snapScroll}>
                <Divider className={styles.homeSectionDivider} textAlign={"center"}>{t("content.homepage.teamSection.title")}</Divider>
            </Grid>
            <Grid item xs={12}>
                <TeamsSection/>
            </Grid>
        </Grid>
    </div>
}