import styles from "./news-page.module.scss";
import {Masonry} from "@mui/lab";
import {NewsCard} from "../../components/news/NewsCard";
import React, {useEffect, useState} from "react";
import {NewsEditor} from "../../components/news/editor/NewsEditor";
import {useArticleService} from "../../hooks/services/ArticleService";
import {Article, SearchableArticleSortableFields, SearchableSortDirection} from "../../API";
import {NewsWidgetCardSkeleton} from "../../components/news/NewsCardSkeleton";
import _ from "lodash";
import {useUserData} from "../../context/UserContext";
import {UserGroups} from "../../helpers/UserGroups";

export const NewsPage = () => {
    const userData = useUserData();
    const {searchArticles} = useArticleService();
    const [articles, setArticles] = useState<Array<Article>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        searchArticles({
            sort: [
                {field: SearchableArticleSortableFields.createdAt, direction: SearchableSortDirection.desc}
            ],
            limit: 25
        })
            .then(a => setArticles(a))
            .finally(() => setLoading(false));
    }, [searchArticles]);

    const renderNewsMasonry = () => {
        if (loading)
            return Array.from({length: _.random(7, 10)}).map(() => <NewsWidgetCardSkeleton key={Math.random()}/>)

        return articles.filter(a => {
            if(!a.active) {
                return !!(userData && userData["custom:azure_groups"].includes(UserGroups.ADMIN));
            } else {
                return true;
            }
        })
            .map((a) =>
                <NewsCard key={a.id}
                          article={a}
                          to={`/news/${a.id}`}
                />
            )
    }

    return <div className={styles.newsMasonryContainer}>
        <Masonry columns={3} spacing={2}>
            {renderNewsMasonry()}
        </Masonry>
        <NewsEditor/>
    </div>
}