import React, {Children, forwardRef, ReactNode, useImperativeHandle, useRef, useState} from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from "./question-carousel.module.scss";

import "./ui-kit-overrides.scss";
import classNames from "classnames";
import {Typography} from "@mui/material";

type QuestionCarouselProps = {
    children: ReactNode,
    itemsPerSlide: number,
    title?: string,
    action?: ReactNode,
}

export type QuestionCarouselType = {
    slideTo: Function
}

const QuestionCarousel = forwardRef<QuestionCarouselType, QuestionCarouselProps>((props, ref) => {
    const {itemsPerSlide, title, action} = props;
    const {children} = props;
    const [sliderIndex, setSliderIndex] = useState(0);
    const carousel = useRef<HTMLUListElement | null>(null);

    const slideTo = (index: number) => {
        if (carousel && carousel.current) {
            carousel.current?.scrollTo(index * carousel.current?.offsetWidth, 0);
            setSliderIndex(index);
        }
    }

    useImperativeHandle(ref, () => {
        return {
            slideTo
        };
    }, [slideTo]);

    const sliderPrev = () => {
        if (carousel && carousel.current && sliderIndex > 0) {
            const newIndex: number = (sliderIndex - 1);
            setSliderIndex(newIndex);
            carousel.current?.scrollTo(newIndex * carousel.current?.offsetWidth, 0);
        }
    }

    const sliderNext = () => {
        if (carousel && carousel.current) {
            const newIndex: number = (sliderIndex + 1);
            if (newIndex < (Children.count(children) / itemsPerSlide)) {
                setSliderIndex(newIndex);
                carousel.current?.scrollTo(newIndex * carousel.current?.offsetWidth, 0);
            }
        }
    }

    const renderTitle = () => {
        if (title) {
            return <Typography variant="h5" align={"center"}>{title}</Typography>
        }
    }

    const renderQuestionsCarousel = () => {
        if (children) {
            return <>
                <button className={styles.handle} onClick={sliderPrev} disabled={sliderIndex === 0}>
                    <ChevronLeftIcon className={styles.icon}/>
                </button>
                <ul ref={carousel} className={styles.standardCarousel}>
                    {children}
                </ul>
                <button className={styles.handle} onClick={sliderNext} disabled={sliderIndex === Math.ceil(Children.count(children) / itemsPerSlide) - 1 }>
                    <ChevronRightIcon className={styles.icon}/>
                </button>
            </>
        }
        return <div>Keine Fragen vorhangen.</div>
    }


    const renderBullets = () => {
        let auditQuestions: ReactNode[] = [];
        for (let i = 0; i < Children.count(children) / itemsPerSlide; i++) {
            if (i === sliderIndex) {
                auditQuestions.push(
                    <button key={"bullet-" + i} className={classNames(styles.bullet, styles.active)} onClick={() => slideTo(i)}/>
                )
            } else {
                auditQuestions.push(
                    <button key={"bullet-" + i} className={styles.bullet} onClick={() => slideTo(i)}/>
                )
            }
        }
        return auditQuestions;
    }

    const renderCarouselBullets = () => {
        return <div className={styles.carouselBullets}>
            {renderBullets()}
        </div>
    }

    return <>
        {renderTitle()}
        {renderCarouselBullets()}
        <div className={styles.questionCarouselContainer}>
            {renderQuestionsCarousel()}
        </div>
        <div className={styles.questionCarouselActions}>
            {action}
        </div>
    </>
});

export default QuestionCarousel;