import {Audit, AuditPhase, CaseStatus, UpdateAuditInput} from "../../API";
import {useCallback} from "react";
import {omitObjectPaths} from "../../helpers/CommonUtils";
import {useAuditService} from "./AuditService";
import {Notification} from "../../helpers/notification/Notification";
import {useCaseData} from "../../context/CaseContext";
import {useCaseService} from "./CaseService";


const UpdateAuditInputPropReference: UpdateAuditInput = {
    _version: undefined,
    caseID: undefined,
    description: undefined,
    editors: undefined,
    id: "",
    isActive: undefined,
    isTemplate: undefined,
    name: undefined,
    originAuditID: undefined,
    owner: undefined,
    phase: undefined
}

export const useAuditAllocationService = () => {
    const {currentCase, audits, setAudits, setCase} = useCaseData();
    const {appendAudit, updateAudit} = useAuditService();
    const {updateCase} = useCaseService();

    /** When user changes an answer-selection WITH audit reference to an answer-selection WITHOUT a reference,
     * the already allocated audits should be deactivated. The allocated audits should not be deleted,
     * because otherwise already added information will be lost.
     * @param {Set<string>} auditReferences - Collection with unique audit IDs of current answer-selection references for a given AUDIT-PHASE.
     */
    const filterAuditsForDeactivation = useCallback((auditReferences: Set<string>, currentPhase: AuditPhase): Array<UpdateAuditInput> => {
        return omitObjectPaths<Audit>(audits, Object.keys(UpdateAuditInputPropReference))
            .filter(c => currentPhase === AuditPhase.basic ? c.phase === AuditPhase.thematic : 1)
            .filter(c => currentPhase === AuditPhase.thematic ? c.phase === AuditPhase.specific : 1)
            .filter(c => c.originAuditID && !auditReferences.has(c.originAuditID))
            .filter(a => a.isActive)
            .map(a => ({id: a.id, isActive: false, _version: a._version}));
    }, [audits]);

    /** When user changes an answer-selection WITHOUT audit reference to an answer-selection WITH a reference,
     * the already allocated but deactivated audits should be reactivated. So the old allocated audits information can be reused.
     * @param {Set<string>} auditReferences - Collection with unique audit IDs of current answer-selection references for a given AUDIT-PHASE.
     */
    const filterAuditsForReactivation = useCallback((auditReferences: Set<string>): Array<UpdateAuditInput> => {
        return audits.filter((a) => a.originAuditID && !a.isActive && auditReferences.has(a.originAuditID))
            .map((a) => ({id: a.id, isActive: true, _version: a._version}))
    }, [audits]);

    const removeAlreadyAllocatedAudits = useCallback((auditReferences: Set<string>) => {
        audits.forEach(a => a.originAuditID && auditReferences.delete(a.originAuditID));
    }, [audits]);

    const updateAllocatedAudits = useCallback(async (auditReferences: Set<string>, currentPhase: AuditPhase) => {
        const activationDataQueue = [...filterAuditsForDeactivation(auditReferences, currentPhase), ...filterAuditsForReactivation(auditReferences)];
        let updatedAudits = new Array<Audit>()
        for (const input of activationDataQueue) {
            updatedAudits.push(await updateAudit(input));
        }
        // const newAudits = audits.map(a => updatedAudits.findIndex(ua => ua.id === a.id) > -1 ? updatedAudits[updatedAudits.findIndex(ua => ua.id === a.id)] : a);
        // setAudits(newAudits);
        await removeAlreadyAllocatedAudits(auditReferences);
    }, [filterAuditsForDeactivation, filterAuditsForReactivation, removeAlreadyAllocatedAudits, updateAudit]);

    const updateCaseReferences = useCallback(async (auditReferences: Set<string>): Promise<void> => {
        if (currentCase) {
            for await (const reference of auditReferences.keys()) {
                await appendAudit(reference, currentCase.id);
            }
        }
    }, [appendAudit, currentCase]);

    const getNextPhase = (phase: AuditPhase | null | undefined) => {
        if (phase === AuditPhase.basic) {
            return AuditPhase.thematic;
        } else if (phase === AuditPhase.thematic) {
            return AuditPhase.specific;
        } else if (phase === AuditPhase.specific) {
            return AuditPhase.specific;
        }
        return AuditPhase.basic;
    }

    const getStatus = (phase: AuditPhase | null | undefined) => {
        if (phase === AuditPhase.basic || phase === AuditPhase.thematic) {
            return CaseStatus.inProgress;
        } else if (phase === AuditPhase.specific) {
            return CaseStatus.inReview;
        }
        return CaseStatus.open;
    }

    const updateCasePhase = useCallback(async (currentPhase: AuditPhase, completePhase: boolean) => {
        if (currentCase) {
            updateCase({
                id: currentCase.id,
                _version: currentCase._version,
                phase: completePhase ? currentPhase : getNextPhase(currentPhase),
                status: completePhase ? CaseStatus.inReview : getStatus(currentPhase)
            })
                .then(c => {
                    setCase(c);
                    setAudits(c.audits?.items);
                })
                .catch(e => Notification.error("Could not update case phase", e));
        }
    }, [currentCase, setAudits, setCase, updateCase]);

    const allocateAuditToCase = useCallback(async (auditReferences: Set<string>, currentPhase: AuditPhase) => {
        if (currentCase) {
            // Do not change execution order. auditReferences.size will change due
            const completePhase: boolean = auditReferences.size === 0;
            await updateAllocatedAudits(auditReferences, currentPhase);
            await updateCaseReferences(auditReferences);
            await updateCasePhase(currentPhase, completePhase);
        }
    }, [currentCase, updateAllocatedAudits, updateCasePhase, updateCaseReferences]);

    return {allocateAuditToCase}
}


