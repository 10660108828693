import React from "react"
import styles from "./footer.module.scss"

export const Footer = () => {
    const currentYear = new Date().getFullYear();
    return <footer className={styles.footer}>
        <div className={styles.flexContainer}>
            <p role="note">
                {currentYear} &copy; VHV Versicherung
            </p>
            <div className={styles.flexItem}>
                <a href={"/"} target="_blank" rel="noopener noreferrer">
                    <span>Terms</span>
                </a>
                <a href={"/"} target="_blank" rel="noopener noreferrer">
                    <span>notice</span>
                </a>
                <a href={"/"} target="_blank" rel="noopener noreferrer">
                    <span>dataProtection</span>
                </a>
                <a href={"/attribution-credits"} rel="noopener noreferrer">
                    <span>Quellen</span>
                </a>
            </div>
        </div>
    </footer>
}
