import {Card, CardActionArea, CardActions, CardContent, CardHeader, Skeleton, Typography} from "@mui/material";
import styles from "./news-widget-card.module.scss";
import React from "react";
import _ from "lodash";

export const NewsWidgetCardSkeleton = () => {

    const createRandomContentRows = () => {
        return Array.from({length: _.random(2, 6)}).map(() => <Skeleton key={Math.random()}/>)
    }

    return <Card raised>
        <CardActionArea>
            <CardHeader className={styles.newsHeader}
                        title={
                            <Typography variant="caption" className={styles.newsTitle}>
                                <Skeleton width={"90%"}/>
                            </Typography>
                        }
                        subheader={
                            <Typography variant="subtitle1" className={styles.newsDate}>
                                <Skeleton width={"50%"}/>
                            </Typography>
                        }
                        action={
                            <Skeleton variant="circular" height={30} width={30}/>
                        }
            />
            <CardContent sx={{paddingTop: "0", paddingBottom: "0.5rem"}}>
                <Typography variant="body2">
                    {createRandomContentRows()}
                    <Skeleton width={"50%"}/>
                </Typography>
            </CardContent>
            <CardActions>
                <Skeleton className={styles.chipSkeleton} width={55}/>
                <Skeleton className={styles.chipSkeleton} width={60}/>
            </CardActions>
        </CardActionArea>
    </Card>
}