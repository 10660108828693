import {Avatar, Card, CardContent, CardHeader, IconButton, Tooltip, Typography, Zoom} from "@mui/material";
import React from "react";
import {TeamsIcon} from "../../../../components/icons/TeamsIcon";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {useTranslation} from "react-i18next";

type TeamContactProps = {
    mail: string,
    name: string,
    role: string,
    image?: string,
    description?: string,
}

export const TeamContact = (props: TeamContactProps) => {
    const {image, name, role, mail, description} = props;
    const {t} = useTranslation();

    const renderAvatar = () => {
        if (image) {
            return <Avatar sx={{height: 100, width: 100}} src={image}>
                {name.at(0)}
            </Avatar>
        } else {
            return <Avatar sx={{height: 100, width: 100}} src={image}>
                {name.at(0)}
            </Avatar>
        }
    }

    return <Card variant={"outlined"}>
        <CardHeader
            avatar={renderAvatar()}
            action={
                <>
                    <Tooltip title={t("content.homepage.teamSection.mail")}
                             TransitionComponent={Zoom}
                             arrow>
                        <IconButton target={"_blank"} href={`mailto:${mail}?subject=ACAP:%20Erstkontakt`}>
                            <AlternateEmailIcon color="secondary"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("content.homepage.teamSection.chat")}
                             TransitionComponent={Zoom}
                             arrow>
                        <IconButton target={"_blank"} href={`https://teams.microsoft.com/l/chat/0/0?users=${mail}&topicName==ACAP:%20Erstkontakt`}>
                            <TeamsIcon color="secondary"/>
                        </IconButton>
                    </Tooltip>
                </>
            }
            title={name}
            subheader={role}
        />
        {description && <CardContent>
            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>
        </CardContent>
        }
    </Card>
}