import {Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Grid, Grow, Paper, Typography} from "@mui/material";
import classNames from "classnames";
import styles from "../home-page.module.scss";
import React, {useRef} from "react";
import useOnScreen from "../../../hooks/useOnScreen";
import {useTranslation} from "react-i18next";

type InformationSectionContent = {
    i18nPrefix?: string,
    href?: string,
    imgSrc?: string,
}

type InformationSectionProps = {
    i18nTitle?: string,
    i18nContent: InformationSectionContent[]
}

export const InformationSection = ({i18nContent, i18nTitle}: InformationSectionProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, true);
    const {t} = useTranslation();

    const renderDeepDiveItem = () => {
        return i18nContent.map((c, i) => {
            if (c.imgSrc) {
                return <Grid key={i + c.imgSrc} item xs={6}>
                    <CardMedia
                        component="img"
                        height="100%"
                        image={c.imgSrc}
                    />
                </Grid>
            } else {
                return <Grid key={c.i18nPrefix} item xs={6}>
                    <Card variant={"outlined"} sx={{height: "100%"}}>
                        <CardActionArea sx={{height: "100%"}} href={c.href || ""} target={"_blank"}>
                            <CardHeader title={t(`${c?.i18nPrefix}.title`)}/>
                            <CardContent>
                                <Typography gutterBottom variant="body1" color="text.secondary">
                                    {t(`${c?.i18nPrefix}.content`)}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{marginLeft: "0.5rem"}}>
                                <Typography gutterBottom variant="body2" color="text.secondary">
                                    {t(`${c?.i18nPrefix}.more`)}
                                </Typography>
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </Grid>
            }
        })
    }

    const renderDeepDiveInformation = () => {
        return <Grid container spacing={5}>
            {renderDeepDiveItem()}
        </Grid>
    }

    return <Grow in={isVisible} timeout={1000}>
        <Paper ref={ref} className={classNames(styles.sectionContainer, styles.informationSection)} variant="outlined">
            <Typography className={styles.sectionTitle} variant={"h3"}>
                {t(i18nTitle)}
            </Typography>
            {renderDeepDiveInformation()}
        </Paper>
    </Grow>
}