import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputBase,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Switch,
    Tooltip
} from "@mui/material";
import styles from "./question-carousel.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import React, {ReactNode, useEffect, useState} from "react";
import {anyMatchForObjectKey, matchBySelectionList, matchByToggle} from "../../../../helpers/CommonUtils";
import {Question} from "../../../../API";
import {useTranslation} from "react-i18next";

type CarouselFilterProps = {
    items: Array<any>,
    action?: ReactNode,
    onFilterChange: (filter: any[]) => any
}

export const CarouselFilter = ({items, action, onFilterChange}: CarouselFilterProps) => {
    const {t} = useTranslation();
    const [favourite, setFavourite] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState<Array<Question>>([]);
    const [searchItem, setSearchItem] = useState<string>("");

    useEffect(() => {
        const filterItem = (object: any) => {
            const search = anyMatchForObjectKey(object, ["question"], searchItem);
            const favouriteActivated = matchByToggle(object, "favourite", favourite);
            const isSelected = matchBySelectionList(object, "id", selectedQuestions.map(q => q.id));
            return search && favouriteActivated && isSelected;
        }
        const result = items.filter(q => filterItem(q));
        onFilterChange(result);
    }, [onFilterChange, items, favourite, searchItem, selectedQuestions])

    const handleChange = (event: SelectChangeEvent<Array<string>>) => {
        const {
            target: {value},
        } = event;
        let selectedReferences;
        if (typeof value === "string") {
            selectedReferences = items.filter(a => value.split(",").includes(a.id));
        } else {
            selectedReferences = items.filter(a => value.includes(a.id));
        }
        setSelectedQuestions(selectedReferences);
    };

    const clearFilter = () => {
        setSearchItem("");
        setFavourite(false);
        setSelectedQuestions([]);
    }

    const renderFilterBar = () => {
        return <Paper className={styles.filterContainer}>
            <div className={styles.filterBar}>
                {action}
                <InputBase
                    placeholder={t("action.search.questions")}
                    inputProps={{"aria-label": t("action.search.questions")}}
                    value={searchItem}
                    onChange={(e) => setSearchItem(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                />
                <Divider orientation="vertical"/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={favourite}
                            onChange={() => setFavourite(!favourite)}
                        />
                    }
                    label={t("content.common.marked")}
                />
                <FormControl hiddenLabel className={styles.formControl}>
                    <Select
                        multiple
                        displayEmpty
                        value={selectedQuestions.map(r => r.id)}
                        onChange={handleChange}
                        input={<OutlinedInput margin="dense" size="small"/>}
                        renderValue={(selected) => {
                            if (selected.length === 0)
                                return t("content.common.questions") + " " + t("content.common.selection");
                            if (selected.length === 1)
                                return selected.length + " " + t("content.common.question");
                            return selected.length + " " + t("content.common.questions");
                        }}
                    >
                        {items
                            .map((q) =>
                                <MenuItem key={q.id} value={q.id}>
                                    <Checkbox checked={selectedQuestions.map(s => s.id).includes(q.id)}/>
                                    <ListItemText primary={`${q.orderID}. ${q.question}`} secondary={q.id}/>
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <Tooltip title={t("action.reset.filter")}>
                    <IconButton size="medium" onClick={() => clearFilter()} color={"primary"} sx={{m: 1}}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    }

    return renderFilterBar();
}