import {Trans} from "react-i18next"
import React, {ReactNode} from "react"
import {Typography} from "@mui/material";


type TranslatedTypographyProps = {
    children?: ReactNode,
    i18nKey?: string,
    count?: number,
    [x: string]: any;
}


/**
 * @deprecated: Does not rerender component on language change. Use Hook useTranslation instead: const {t} = useTranslation();
 */
export const TranslatedTypography = (props: TranslatedTypographyProps) => {
    const {children, i18nKey, count = 1, ...otherProps} = props;

    return <Typography {...otherProps}>
        <Trans i18nKey={i18nKey} count={count}>
            {children}
        </Trans>
    </Typography>
}
